import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import DirectoryPage from 'components/pages/DirectoryPage/DirectoryPage';
import TeamPage from 'components/pages/TeamPage/TeamPage';
import UserPage from 'components/pages/UserPage/UserPage';

export const Pages: FunctionComponent = () => {
    return (
        <div className="mycrew-pages">
            <Switch>
                <Route exact path="/" component={DirectoryPage} />
                <Route path="/user/:id" component={UserPage} />
                <Route path="/team/:team" component={TeamPage} />
            </Switch>
        </div>
    );
};

export default Pages;
