import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import { AssignmentInd, Search } from '@material-ui/icons';
import { isDesktop } from 'react-device-detect';

import API_CONFIG from 'api/apiConfig';

import { IRootStore } from 'interfaces/common';

import LogoSymbol from 'components/svgs/64/LogoSymbol';
import { TabItem, Translation } from 'constants/CommonConstants';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { setSearchFocus } from 'state/actions/SearchActions';
import { getUserSelected } from 'state/actions/UserActions';

import {focusAndOpenKeyboard, scrollTop} from 'utils/helpers';

import colors from 'styles/_colors.module.scss';
import 'components/molecules/TabBar/TabBar.scss';

export const TabBar = () => {
    const { isThemeLight } = useSelector((state: IRootStore) => state.common);
    const { currentUser } = useSelector((state: IRootStore) => state.user);
    const [ activeItem, setActiveItem ] = useState<string>(TabItem.HOME);
    const currentUserPath = `/user/${currentUser?.id}`;

    const dispatch = useDispatch();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.split('/')[1] === TabItem.TEAM) {
            setActiveItem(TabItem.TEAM);
        }

        if (pathname !== currentUserPath && pathname.split('/')[1] === TabItem.USER) {
            setActiveItem(TabItem.USER);
        }

        if (pathname === currentUserPath) {
            setActiveItem(TabItem.MY_PROFILE);
        }
    }, [pathname, currentUserPath]);

    if (isDesktop) {
        return null;
    }

    const handleHome = () => {
        setActiveItem(TabItem.HOME);
    };

    const handleSearch = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setActiveItem(TabItem.SEARCH);
        const getAllSearchInput: HTMLInputElement | null = document.querySelector('.mycrew-all-search__input > .MuiInput-input');
        getAllSearchInput && focusAndOpenKeyboard(getAllSearchInput);
        dispatch(setSearchFocus(true));
    };

    const handleMyProfile = () => {
        if (pathname.split('/')[1] === API_CONFIG.USER) {
            scrollTop(true, true);
        }

        setActiveItem(TabItem.MY_PROFILE);
        dispatch(getUserSelected(currentUser?.id ?? ''));
    };

    return (
        <div className={`mycrew-tabbar ${activeItem}`}>
            <ul className="mycrew-tabbar__wrapper">
                <li className="mycrew-tabbar__item">
                    <Link
                        className="mycrew-tabbar__link"
                        component={RouterLink}
                        onClick={handleHome}
                        to="/"
                        underline="none"
                    >
                        <span className="mycrew-tabbar__icon-wrapper">
                            <LogoSymbol iconColor={isThemeLight ? colors.colorThemeLightAlt : colors.colorWhite} iconSize={20} className="mycrew-tabbar__icon" />
                        </span>
                        <span className="mycrew-tabbar__label">{Translation.MY_CREW}</span>
                    </Link>
                </li>
                <li className="mycrew-tabbar__item">
                    <Link
                        className="mycrew-tabbar__link"
                        component={RouterLink}
                        onClick={(event: React.SyntheticEvent) => handleSearch(event)}
                        to="/"
                        underline="none"
                    >
                        <span className="mycrew-tabbar__icon-wrapper">
                            <Search className="mycrew-tabbar__icon" />
                        </span>
                        <span className="mycrew-tabbar__label">{Translation.SEARCH}</span>
                    </Link>
                </li>
                <li className="mycrew-tabbar__item">
                    <Link
                        className="mycrew-tabbar__link"
                        component={RouterLink}
                        onClick={handleMyProfile}
                        to={currentUserPath}
                        underline="none"
                    >
                        <span className="mycrew-tabbar__icon-wrapper">
                            <AssignmentInd className="mycrew-tabbar__icon" />
                        </span>
                        <span className="mycrew-tabbar__label">{Translation.MY_PROFILE}</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default TabBar;
