import React, { FunctionComponent, memo } from 'react';
import { ISVGAttributes } from 'interfaces/svg';

const Loading: FunctionComponent<ISVGAttributes> = (props) => {
    const { iconColor, className } = props;

    return (
        <svg
            className={className}
            height="32px"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 100 100"
            width="32px"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g transform="rotate(0 50 50)">
                <rect x="37.5" y="0.5" rx="12.5" ry="19.5" width="25" height="39" fill={iconColor}>
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="0.8s"
                        begin="-0.64s"
                        repeatCount="indefinite"
                    />
                </rect>
            </g>
            <g transform="rotate(72 50 50)">
                <rect x="37.5" y="0.5" rx="12.5" ry="19.5" width="25" height="39" fill={iconColor}>
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="0.8s"
                        begin="-0.48s"
                        repeatCount="indefinite"
                    />
                </rect>
            </g>
            <g transform="rotate(144 50 50)">
                <rect x="37.5" y="0.5" rx="12.5" ry="19.5" width="25" height="39" fill={iconColor}>
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="0.8s"
                        begin="-0.32s"
                        repeatCount="indefinite"
                    />
                </rect>
            </g>
            <g transform="rotate(216 50 50)">
                <rect x="37.5" y="0.5" rx="12.5" ry="19.5" width="25" height="39" fill={iconColor}>
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="0.8s"
                        begin="-0.16s"
                        repeatCount="indefinite"
                    />
                </rect>
            </g>
            <g transform="rotate(288 50 50)">
                <rect x="37.5" y="0.5" rx="12.5" ry="19.5" width="25" height="39" fill={iconColor}>
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="0.8s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </rect>
            </g>
        </svg>
    );
};

export default memo(Loading);
