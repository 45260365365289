import API_CONFIG from 'api/apiConfig';
import { Translation } from 'constants/CommonConstants';
import { ModalType } from 'constants/PageConstants';
import React from 'react';
import Flicking from '@egjs/react-flicking';
import { isMobile } from 'react-device-detect';
import { More } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { IRootStore } from 'interfaces/common';

import Link from '@material-ui/core/Link';

import Loading from 'components/svgs/32/Loading';
import { Link as RouterLink } from 'react-router-dom';
import { setModalOpen } from 'state/actions/ModalActions';
import { setTeamsSelected } from 'state/actions/TeamActions';

import colors from 'styles/_colors.module.scss';
import 'components/molecules/Header/TeamMenu/TeamMenu.scss';

export const TeamMenu = () => {
    const listOfTeams = useSelector((state: IRootStore) => state.team);
    const dispatch = useDispatch();

    if (!listOfTeams.teams?.length) {
        return null;
    }

    const _renderTeams = () => {
        let teamLink;

        if (!listOfTeams.loading && listOfTeams.teams?.length === 0) {
            if (isMobile) {
                teamLink = (
                    <span className="loading" key={colors.colorBrandPrimary}>
                        <Loading className="spinner" iconColor={colors.colorBrandPrimary} />
                    </span>
                );
            }

            teamLink = (
                <li className="loading" key={colors.colorBrandPrimary}>
                    <Loading className="spinner" iconColor={colors.colorBrandPrimary} />
                </li>
            );
        } else {
            teamLink = listOfTeams.teams?.map((team) => {
                if (isMobile) {
                    return (
                        <span className="mycrew-team-menu__item" key={team}>
                            <Link
                                component={RouterLink}
                                onClick={() => dispatch(setTeamsSelected(team))}
                                title={team}
                                to={`/${API_CONFIG.TEAM}/${team}`}
                                underline="none"
                            >
                                {team}
                            </Link>
                        </span>
                    );
                }

                return (
                    <li className="mycrew-team-menu__item" key={team}>
                        <Link
                            component={RouterLink}
                            onClick={() => dispatch(setTeamsSelected(team))}
                            title={team}
                            to={`/${API_CONFIG.TEAM}/${team}`}
                            underline="none"
                        >
                            {team}
                        </Link>
                    </li>
                );
            });
        }

        return teamLink;
    };

    const handleShowTeams = () => dispatch(setModalOpen(ModalType.OVERLAY_ALL_TEAMS));

    const _renderTeamMenu = () => {
        if (isMobile) {
            return (
                <div className="mycrew-team-menu__wrapper">
                    <Flicking align="prev" bound={true} className="mycrew-team-menu__swipe">
                        {_renderTeams()}
                    </Flicking>
                    <div className="mycrew-team-menu__show-all">
                        <span className="mycrew-team-menu__show-all-button" onClick={handleShowTeams}>
                            <More className="mycrew-team-menu__show-all-icon" />
                            <span className="mycrew-team-menu__show-all-label">{Translation.VIEW_ALL}</span>
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <ul className="mycrew-team-menu-wrapper">
                <Flicking align="prev" bound={true} className="mycrew-team-menu__swipe">
                    {_renderTeams()}
                </Flicking>
            </ul>
        );
    };

    return <div className="mycrew-team-menu">{_renderTeamMenu()}</div>;
};

export default TeamMenu;
