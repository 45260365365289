import { Input, Link } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { setSearchFocus } from 'state/actions/SearchActions';
import { setTeamsSelected } from 'state/actions/TeamActions';

import { getUserSelected } from 'state/actions/UserActions';

import API_CONFIG from 'api/apiConfig';

import { Defaults, Translation } from 'constants/CommonConstants';

import { IUser, IRootStore } from 'interfaces/common';

import 'components/molecules/Header/AllSearch/AllSearch.scss';

import { preventDefault } from 'utils/helpers';

export const AllSearch = () => {
    const { users: allUsers } = useSelector((state: IRootStore) => state.user);
    const { teams: allTeams } = useSelector((state: IRootStore) => state.team);
    const { isSearchActive } = useSelector((state: IRootStore) => state.search);

    const dispatch = useDispatch();

    const [isFocusSearch, setIsFocusSearch] = useState(false);
    const [isResultsOpen, setIsResultsOpen] = useState(false);
    const [searchResultsTeams, setSearchResultsTeams] = useState<string[]>([]);
    const [searchResultsUsers, setSearchResultsUsers] = useState<IUser[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const searchField = useRef<HTMLInputElement>(null);
    const searchResultsList = useRef(null);

    useEffect(() => {
        setIsResultsOpen(!!searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (isSearchActive) {
            handleFocus();
        }
    }, [isSearchActive]);

    useEffect(() => {
        if (isResultsOpen && isFocusSearch && searchResultsUsers.length !== 0 && searchResultsTeams.length !== 0) {
            window.addEventListener('keydown', handleDropdownFocus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isResultsOpen, isFocusSearch, searchResultsUsers, searchResultsTeams]);

    const clearEventListener = () => {
        return window.removeEventListener('keydown', handleDropdownFocus);
    };

    const handleDropdownFocus = (event?: any) => {
        if (event.keyCode === 9 || event.keyCode === 40) {
            event.preventDefault();
            clearEventListener();
            // @ts-ignore
            searchResultsList.current.firstChild.querySelector('a').focus();
        }
    };

    const filterResults = (searchTerm: string) => {
        if (!allUsers && !allTeams) {
            return null;
        }

        const matchedUsers = allUsers
            ? allUsers.filter((user) => user.name?.toLowerCase()
                .replaceAll('č', 'c')
                .replaceAll('ć', 'c')
                .replaceAll('đ', 'dj')
                .replaceAll('š', 's')
                .replaceAll('ž', 'z')
                .includes(searchTerm.toLowerCase()))
            : [];
        const matchedTeams = allTeams
            ? allTeams.filter((team) => team?.toLowerCase().includes(searchTerm.toLowerCase()))
            : [];

        setSearchResultsUsers(matchedUsers);
        setSearchResultsTeams(matchedTeams);
        return null;
    };

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        filterResults(event.currentTarget?.value);
        return setSearchValue(event.currentTarget?.value);
    };

    const handleFocus = () => {
        if (!!searchField.current) {
            searchField.current.focus();
        }
        setIsFocusSearch(true);
    };

    const handleBlur = () => {
        dispatch(setSearchFocus(false));
        setIsFocusSearch(false);
    };

    const clearInput = (event?: React.SyntheticEvent) => {
        if (event) {
            preventDefault(event);
        }

        setSearchValue('');
        setIsResultsOpen(false);
    };

    const _renderSearchIcon = () => {
        if (searchValue.length !== 0) {
            return null;
        }

        return <Search className="mycrew-all-search__input--search-icon" />;
    };

    const _renderClearButton = () => {
        if (searchValue.length === 0) {
            return null;
        }

        return (
            <Link
                className="mycrew-all-search__input--clear"
                href={`#${Translation.CLEAR}`}
                onClick={(event: React.SyntheticEvent) => clearInput(event)}
                title={Translation.CLEAR}
                underline="none"
            >
                <Close className="mycrew-all-search__input--clear-icon" />
            </Link>
        );
    };

    const _renderInput = () => {
        const inputClassName = `mycrew-all-search__input${
            searchValue.length !== 0 ? ' mycrew-all-search__input--active' : ''
        }`;

        return (
            <>
                <Input
                    className={inputClassName}
                    inputProps={{ 'aria-label': 'description' }}
                    inputRef={searchField}
                    onBlur={handleBlur}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e)}
                    onFocus={handleFocus}
                    placeholder={Translation.SEARCH_PLACEHOLDER}
                    value={searchValue}
                />
                {_renderSearchIcon()}
                {_renderClearButton()}
            </>
        );
    };

    const _renderResults = () => {
        if (!isResultsOpen || !searchResultsUsers) {
            return null;
        }

        const isResultsUsers = searchResultsUsers.length === 0;
        const isResultsTeams = searchResultsTeams.length === 0;

        const _generateTeamName = (teamName: string) => {
            const teamNameAbbreviated = teamName.split(' ').map((teamWord: string, index: number) => {
                if (index <= 2) {
                    return teamWord.charAt(0);
                }

                return null;
            });

            return teamNameAbbreviated.join('');
        };

        const _renderResultsEmpty = () => (
            <li className="mycrew-all-search__results--none">
                <span className="label">{Translation.NO_RESULTS}</span>
            </li>
        );

        const handleClickResult = (selectedId: string, isTeam?: boolean) => () => {
            clearInput();
            !isTeam ? dispatch(getUserSelected(selectedId)) : dispatch(setTeamsSelected(selectedId));
        };

        const _renderResultsUsers = () => {
            return searchResultsUsers.map((singleUser) => {
                if (!singleUser || !singleUser.id) {
                    return null;
                }

                return (
                    <li key={singleUser.id}>
                        <Link
                            onClick={handleClickResult(singleUser.id)}
                            component={RouterLink}
                            to={`/${API_CONFIG.USER}/${singleUser.id}`}
                            className="mycrew-all-search__results--link"
                        >
                            <span className="image">
                                <img
                                    src={`${Defaults.URL}/users/${singleUser.id}.png`}
                                    alt={`Avatar of ${singleUser.name}`}
                                    title={singleUser.name}
                                    width="100%"
                                />
                            </span>
                            <span className="name">{singleUser.name}</span>
                            <span className="team">{singleUser.team}</span>
                        </Link>
                    </li>
                );
            });
        };

        const _renderResultsTeams = () => {
            return searchResultsTeams.map((singleTeam) => {
                return (
                    <li key={singleTeam}>
                        <Link
                            onClick={handleClickResult(singleTeam, true)}
                            component={RouterLink}
                            to={`/${API_CONFIG.TEAM}/${singleTeam}`}
                            className="mycrew-all-search__results--link"
                        >
                            <span className="team-abbr">
                                {singleTeam.length > 3 ? _generateTeamName(singleTeam) : singleTeam}
                            </span>
                            <span className="team-full">{singleTeam}</span>
                        </Link>
                    </li>
                );
            });
        };

        return (
            <div className="mycrew-all-search__results">
                <Scrollbars autoHeight autoHeightMin={45} autoHeightMax={408}>
                    <ul className="mycrew-all-search__results--items" ref={searchResultsList}>
                        {isResultsUsers && isResultsTeams && _renderResultsEmpty()}
                        {!isResultsUsers && _renderResultsUsers()}
                        {!isResultsTeams && _renderResultsTeams()}
                    </ul>
                </Scrollbars>
            </div>
        );
    };

    return (
        <div className="mycrew-all-search">
            {_renderInput()}
            {_renderResults()}
        </div>
    );
};

export default AllSearch;
