import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Link } from '@material-ui/core';

import { IRootStore } from 'interfaces/common';

import { Cookie, Defaults, Translation, UserRole } from 'constants/CommonConstants';
import { Link as RouterLink } from 'react-router-dom';
import { setTheme } from 'state/actions/CommonActions';

import { WbSunny, Brightness2, VerifiedUser } from '@material-ui/icons';

import { getUserSelected } from 'state/actions/UserActions';
import { isDesktop, isMobile } from 'react-device-detect';
import { setModalOpen } from 'state/actions/ModalActions';
import { ModalType } from 'constants/PageConstants';

import 'components/molecules/Header/UserPanel/UserPanel.scss';
import { LogoutButton } from "./LogoutButton";

export const UserPanel = () => {
    const [cookies, setCookie] = useCookies([Cookie.THEME]);
    const { isThemeEnabled, isThemeLight } = useSelector((state: IRootStore) => state.common);
    const { isUserLoggedIn } = useSelector((state: IRootStore) => state.login);
    const { currentUser } = useSelector((state: IRootStore) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (cookies.theme && cookies.theme === 'true') {
            dispatch(setTheme(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //
    // THEME TOGGLE
    //
    const _renderMenuThemeToggle = () => {
        if (!isThemeEnabled) {
            return null;
        }

        return (
            <div className="mycrew-user-panel__toggle">
                <div className={`switch${isThemeLight ? ' switched' : ''}`} onClick={() => toggleTheme()}>
                    <span className="circle">{_renderThemeIcon()}</span>
                </div>
            </div>
        );
    };

    const toggleTheme = () => {
        setCookie(Cookie.THEME, !isThemeLight, { path: '/' });
        dispatch(setTheme(!isThemeLight));
        return null;
    };

    const _renderThemeIcon = () =>
        isThemeLight ? (
            <WbSunny className="mycrew-user-panel__toggle-icon mycrew-user-panel__toggle-icon__sun" />
        ) : (
            <Brightness2 className="mycrew-user-panel__toggle-icon mycrew-user-panel__toggle-icon__moon" />
        );

    //
    // USER ROLE
    //
    const _renderMenuUserRole = () => {
        if (!currentUser?.role || currentUser?.role !== UserRole.ADMIN) {
            return null;
        }

        return (
            <div className="mycrew-user-panel__role">
                <VerifiedUser className="mycrew-user-panel__role-icon" />
                {currentUser.role}
            </div>
        );
    };

    //
    // LOG OUT
    //
    const _renderMenuLogOut = () => {
        if (!isUserLoggedIn) {
            return null;
        }

        return (
            <LogoutButton/>
        );
    };

    //
    // USER WELCOME
    //
    const _renderUserWelcome = () => {
        if (!currentUser?.name) {
            return null;
        }

        return (
            <div className="mycrew-user-panel__welcome">
                {Translation.WELCOME} {currentUser?.name?.split(' ')[0]}
            </div>
        );
    };

    //
    // AVATAR
    //
    const _renderUserAvatar = () => {
        if (!currentUser?.id) {
            return null;
        }

        return (
            <div className="mycrew-user-panel__avatar">
                <Link
                    component={RouterLink}
                    to={`/user/${currentUser.id}`}
                    onClick={(event: React.SyntheticEvent) => handleGoToCurrent(event, currentUser?.id)}
                >
                    <img
                        alt={currentUser?.name}
                        height={isDesktop ? 24 : 36}
                        src={`${Defaults.URL}/users/${currentUser.id}.png`}
                        title={`${currentUser?.name} ${currentUser?.position}`}
                        width={isDesktop ? 24 : 36}
                    />
                </Link>
            </div>
        );
    };

    const handleGoToCurrent = (event: React.SyntheticEvent<Element, Event>, currentUserId: string | undefined) => {
        if (isMobile) {
            event.preventDefault();
            return dispatch(setModalOpen(ModalType.USER_MENU));
        }

        if (currentUserId) {
            dispatch(getUserSelected(currentUserId));
        }
    };

    if (isMobile) {
        return (
            <div className="mycrew-user-panel">
                {_renderUserAvatar()}
            </div>
        )
    }

    return (
        <div className="mycrew-user-panel">
            <div className="mycrew-user-panel__menu">
                {_renderMenuThemeToggle()}
                {_renderMenuUserRole()}
                {_renderMenuLogOut()}
            </div>
            <div className="mycrew-user-panel__user">
                {_renderUserWelcome()}
                {_renderUserAvatar()}
            </div>
        </div>
    );
};

export default UserPanel;
