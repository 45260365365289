import { Input } from '@material-ui/core';
import { ContactSupport, DoneAll } from '@material-ui/icons';
import { LinkTypeLabel, Translation } from 'constants/CommonConstants';
import { ModalInputTypes, ModalType } from 'constants/PageConstants';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { ValueType } from 'react-select';
import { firstToUppercase } from 'utils/helpers';
import { addUserLink } from 'state/actions/UserActions';
import ModalContext from 'components/molecules/Modal/context/ModalContext';
import { IDefaultIndex, IDefaultKeyString, IModalConfigType, IRootStore, ISelectMenuValues } from 'interfaces/common';
import 'components/pages/UserPage/links/add/LinkAdd.scss';

export const LinkAdd: React.FunctionComponent = () => {
    const { isUserUpdated, userErrors, selected } = useSelector((state: IRootStore) => state.user);
    const { config } = useSelector((state: IRootStore) => state.modal);

    const selectedUser = selected;
    const dispatch = useDispatch();

    const [inputValues, setInputValues] = useState<any>({});
    const [isCustom, setIsCustom] = useState<boolean>(false);
    const [isLinkAddValid, setIsLinkAddValid] = useState<boolean>(false);
    const [menuLevelOne, setMenuLevelOne] = useState<ISelectMenuValues[]>([]);
    const [menuLevelOneValue, setMenuLevelOneValue] = useState<string>();
    const [menuLevelTwo, setMenuLevelTwo] = useState<ISelectMenuValues[]>([]);
    const [menuLevelTwoInputs, setMenuLevelTwoInputs] = useState<string[]>([]);
    const [menuLevelTwoValue, setMenuLevelTwoValue] = useState<ISelectMenuValues | null>();
    const testTitle: RegExp = new RegExp('^[a-zA-Z0-9 ]*$');
    const testUrl: RegExp = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    );

    useEffect(() => {
        const modalConfig: IModalConfigType | IDefaultKeyString =
            config[ModalType.LINK_ADD as keyof IModalConfigType].type ?? {};

        if (modalConfig) {
            setMenuLevelOne(
                Object.entries(modalConfig).map((linkType: [string, string[]]) =>
                    formatOptionsObject(linkType[0], firstToUppercase(linkType[0]))
                )
            );
        }

        if (menuLevelOneValue && !!modalConfig && !!modalConfig[menuLevelOneValue]) {
            const optionsOfSelected: IModalConfigType = modalConfig[menuLevelOneValue];

            setMenuLevelTwo(
                optionsOfSelected.options.map((linkTypeItem: string) => formatOptionsObject(linkTypeItem, linkTypeItem))
            );

            setMenuLevelTwoInputs(optionsOfSelected.inputs);
        }

        setIsCustom(menuLevelOneValue !== LinkTypeLabel.CUSTOM);
    }, [menuLevelOneValue, config]);

    const formatOptionsObject = (value: string, label: string) => ({ value, label });

    const _renderDropdownOne = () => {
        if (!menuLevelOne || menuLevelOne.length === 0) {
            return null;
        }

        return (
            <div className="mycrew-modal__content-block">
                <span className="dropdown-label">{Translation.LINK_TYPE}</span>
                <Select
                    className="dropdown-select"
                    classNamePrefix="dropdown-select"
                    onChange={(selectedValue: ValueType<ISelectMenuValues, false>) => handleSelect(selectedValue)}
                    options={menuLevelOne}
                />
            </div>
        );
    };

    const _renderDropdownTwo = () => {
        if (!menuLevelTwo || menuLevelTwo.length === 0 || !menuLevelOneValue) {
            return null;
        }

        return (
            <div className="mycrew-modal__content-block mycrew-modal__content-block--new">
                <span className="dropdown-label">{Translation.LINK_SUB_TYPE}</span>
                <Select
                    className="dropdown-select"
                    classNamePrefix="dropdown-select"
                    onChange={(selectedValue: ValueType<ISelectMenuValues, false>) => handleSelect(selectedValue, true)}
                    options={menuLevelTwo}
                    value={menuLevelTwoValue}
                />
            </div>
        );
    };

    //
    // VALUE
    //
    const _renderValue = () => {
        if (!menuLevelTwoInputs.includes(ModalInputTypes.VALUE)) {
            return null;
        }

        const inputValue = inputValues[ModalInputTypes.VALUE] ? inputValues[ModalInputTypes.VALUE] : '';
        const inputClassName = `dropdown-input${isInputValid(inputValue, testUrl) ? '' : ' dropdown-input-error'}`;

        return (
            <div className="mycrew-modal__content-block mycrew-modal__content-block--new">
                <span className="dropdown-label">{Translation.LABEL_URL}</span>
                {!isInputValid(inputValue, testUrl) && (
                    <span className="dropdown-label--error">{Translation.ERROR_URL}</span>
                )}
                <Input
                    className={inputClassName}
                    disableUnderline={true}
                    disabled={!menuLevelTwoValue && isCustom}
                    inputProps={{ minLength: 3, maxLength: 140 }}
                    onBlur={() => isFormValid()}
                    onChange={(event) => handleInput(event, ModalInputTypes.VALUE)}
                    placeholder={Translation.URL_PLACEHOLDER}
                    value={inputValue}
                />
            </div>
        );
    };

    //
    // TITLE
    //
    const _renderTitle = () => {
        if (!menuLevelTwoInputs.includes(ModalInputTypes.TITLE)) {
            return null;
        }

        const inputTitle = inputValues[ModalInputTypes.TITLE] ? inputValues[ModalInputTypes.TITLE] : '';
        const inputClassName = `dropdown-input${isInputValid(inputTitle, testTitle) ? '' : ' dropdown-input-error'}`;

        // const optionalRender = () => <>({Translation.LABEL_OPTIONAL})<span className="dropdown-label__notice">*</span></>;

        return (
            <div className="mycrew-modal__content-block mycrew-modal__content-block--new">
                <span className="dropdown-label">{Translation.LABEL_TITLE}</span>
                {!isInputValid(inputTitle, testTitle) && (
                    <span className="dropdown-label--error">{Translation.ERROR_SPECIAL_CHARACTERS}</span>
                )}
                <Input
                    className={inputClassName}
                    disableUnderline={true}
                    disabled={!menuLevelTwoValue && isCustom}
                    inputProps={{ minLength: 3, maxLength: 20 }}
                    onSubmit={handleLinkAdd}
                    onBlur={() => isFormValid()}
                    onChange={(event) => handleInput(event, ModalInputTypes.TITLE)}
                    placeholder={Translation.LABEL_TITLE}
                    value={inputTitle}
                />
            </div>
        );
    };

    const _renderInputs = () => {
        if ((menuLevelTwoInputs.length === 0 && isCustom) || (!menuLevelTwoValue && isCustom)) {
            return null;
        }

        return (
            <>
                {_renderValue()}
                {_renderTitle()}
            </>
        );
    };

    const handleInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, inputType: string) => {
        const passInput: IDefaultIndex = {};
        passInput[inputType] = event.currentTarget.value;
        setIsLinkAddValid(true);

        return setInputValues({ ...inputValues, ...passInput });
    };

    const handleSelect = (selectedValue: ValueType<ISelectMenuValues, false>, levelTwo?: boolean) => {
        if (menuLevelOne.length !== 0) {
            _renderDropdownOne();
            setMenuLevelTwoValue(null);
        }

        if (menuLevelTwo.length !== 0) {
            _renderDropdownTwo();
        }

        return levelTwo ? setMenuLevelTwoValue(selectedValue) : setMenuLevelOneValue(selectedValue?.value);
    };

    const isInputValid = (value: string, pattern: RegExp) => {
        if (value === '') {
            return true;
        }

        return pattern.test(value);
    };

    const isFormValid = () => {
        if (!menuLevelTwoInputs.includes(ModalInputTypes.TITLE)) {
            return setIsLinkAddValid(
                isInputValid(inputValues[ModalInputTypes.VALUE], testUrl) && !!inputValues[ModalInputTypes.VALUE]
            );
        }

        return setIsLinkAddValid(
            isInputValid(inputValues[ModalInputTypes.VALUE], testUrl) &&
                isInputValid(inputValues[ModalInputTypes.TITLE], testTitle) &&
                !!inputValues[ModalInputTypes.VALUE] &&
                !!inputValues[ModalInputTypes.TITLE]
        );
    };

    const handleLinkAdd = () => {
        const linkPayload = {
            type: menuLevelOneValue,
            title: menuLevelOneValue === LinkTypeLabel.CUSTOM ? inputValues?.title : menuLevelTwoValue?.label,
            url: inputValues?.value
        };

        if (!selectedUser) {
            return null;
        }

        if (!selectedUser.id || !linkPayload.type || !linkPayload.title || !linkPayload.url) {
            return null;
        }

        dispatch(addUserLink(selectedUser.id, linkPayload));
        return null;
    };

    const renderContent = () => (
        <>
            {_renderDropdownOne()}
            {_renderDropdownTwo()}
            {_renderInputs()}
        </>
    );

    const renderSuccess = () => (
        <>
            <DoneAll />
            <p>{Translation.LINK_ADD_SUCCESS}</p>
        </>
    );

    const renderError = () => (
        <>
            <ContactSupport />
            <p>{Translation.PROBLEM}</p>
        </>
    );

    if (isUserUpdated && userErrors) {
        return (
            <ModalContext
                classSuffix="error"
                content={renderError()}
                isErroneous={true}
                isSoloFooterOption={true}
                theme="primary"
                title={Translation.ERROR}
            />
        );
    }

    if (isUserUpdated) {
        return (
            <ModalContext
                content={renderSuccess()}
                classSuffix="success"
                isSoloFooterOption={true}
                theme="primary"
                title={Translation.SUCCESS}
            />
        );
    }

    return (
        <ModalContext
            content={renderContent()}
            isSubmitDisabled={!isLinkAddValid}
            onConfirm={handleLinkAdd}
            textConfirm={Translation.LINK_ADD}
            theme="primary"
            title={Translation.LINK_ADD}
        />
    );
};

export default LinkAdd;
