import {
    DIRECTORY_LOADING,
    DIRECTORY_SUCCESS,
    DIRECTORY_FAIL,
    DirectoryActionTypes
} from 'state/actions/DirectoryActionTypes';

import { IUserState } from 'interfaces/common';

const defaultState: IUserState = {
    loading: false,
    users: []
};

const userReducer = (state: IUserState = defaultState, action: DirectoryActionTypes): IUserState => {
    switch (action.type) {
        case DIRECTORY_LOADING:
            return {
                ...state,
                loading: true
            };

        case DIRECTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload
            };

        case DIRECTORY_FAIL:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};

export default userReducer;
