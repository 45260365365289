import { Dispatch } from 'redux';

import {
    MODAL_LOADING_START,
    MODAL_LOADING_END,
    MODAL_UPDATING_START,
    MODAL_UPDATING_END,
    MODAL_SET_OPEN,
    MODAL_SET_CLOSED,
    ModalActionTypes
} from 'state/actions/ModalActionTypes';

export const setModalLoadingStart = () => async (dispatch: Dispatch<ModalActionTypes>) => {
    dispatch({
        type: MODAL_LOADING_START
    });
};

export const setModalLoadingEND = () => async (dispatch: Dispatch<ModalActionTypes>) => {
    dispatch({
        type: MODAL_LOADING_END
    });
};

export const setModalUpdatingStart = () => async (dispatch: Dispatch<ModalActionTypes>) => {
    dispatch({
        type: MODAL_UPDATING_START
    });
};

export const setModalUpdatingEnd = () => async (dispatch: Dispatch<ModalActionTypes>) => {
    dispatch({
        type: MODAL_UPDATING_END
    });
};

export const setModalOpen = (modalType: string) => async (dispatch: Dispatch<ModalActionTypes>) => {
    dispatch({
        type: MODAL_SET_OPEN,
        payload: {
            modalType: modalType
        }
    });
};

export const setModalClosed = () => async (dispatch: Dispatch<ModalActionTypes>) => {
    dispatch({
        type: MODAL_SET_CLOSED
    });
};
