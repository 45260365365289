import { THEME_CHANGE, CommonActionTypes } from 'state/actions/CommonActionTypes';

import { ICommon } from 'interfaces/common';

const defaultState: ICommon = {
    isLoggedIn: false, // Unused
    isThemeEnabled: true,
    isThemeLight: false
};

const commonReducer = (state: ICommon = defaultState, action: CommonActionTypes): ICommon => {
    switch (action.type) {
        case THEME_CHANGE:
            return {
                ...state,
                isThemeLight: action.payload
            };

        default:
            return state;
    }
};

export default commonReducer;
