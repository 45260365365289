import { Defaults } from 'constants/CommonConstants';

const API_CONFIG = {
    AVATAR: 'avatar',
    BASE_URL: `${Defaults.URL}/api/`,
    CLEAN_URL: `${Defaults.URL}/`,
    DIRECTORY: 'directory',
    LINK: 'link',
    WHOAMI: 'whoami',
    TEAM: 'team',
    TOKEN: 'token',
    USER: 'user'
};

export default API_CONFIG;
