import React from 'react';

import { IFocusBody } from 'interfaces/common';

import 'components/molecules/Overlay/body/OverlayBody.scss';

export const OverlayBody: React.FunctionComponent<IFocusBody> = ({ content, classSuffix }) => {
    const classBase = 'mycrew-overlay__body';
    const classOverlayBody = `${classBase}${classSuffix ? ` ${classBase}--${classSuffix}` : ''}`;

    return <div className={classOverlayBody}>{content}</div>;
};

export default OverlayBody;
