export enum ModalType {
    LINK_ADD = 'link-add',
    LINK_DELETE = 'link-delete',
    LINK_EDIT = 'link-edit',
    OVERLAY_ALL_TEAMS = 'overlay-all-teams',
    PHOTO_ADD = 'photo-add',
    PHOTO_DELETE = 'photo-delete',
    PHOTO_EDIT = 'photo-edit',
    USER_MENU = 'user-menu'
}

export enum ModalInputTypes {
    VALUE = 'value',
    TITLE = 'title'
}
