export enum Developer {
    COPYRIGHT = 'Copyright © 2021 myCrew',
    EMAIL = 'office@mycrew.dev',
    MOTO = 'Showcase and Overview',
    TITLE = 'myCrew'
}

export enum Customer {
    DOMAIN = 'demo',
    TITLE = 'Meterio HQ'
}

export enum Branding {
    TAG_LINE = 'The Crew people.',
    TAG_TEXT = 'Discover beyond the scope of your organization.',
    TAG_TEXT_FOLLOW = 'By far the best way to present your people structure.',
    TAG_TEXT_FOLLOW_UP = 'View them as yourself.'
}

export enum UserRole {
    ADMIN = 'admin',
    GUEST = 'guest',
    TEAM = 'team',
    USER = 'user'
}

export const Defaults = {
    ABOUT_TEXT_MAX_ALLOWED: 1480,
    CLIENT_ID: '87980276623-e3c8icr7a11kffmpnj7rrohi9ql3r9dp.apps.googleusercontent.com',
    URL: `https://${Customer.DOMAIN}.mycrew.dev`,
};

export enum Translation {
    ALL_TEAMS = 'All teams',
    ASSIGNED_TO = 'my crew is ',
    AWESOME = 'Your crew is awesome',
    BACK = 'Back',
    CANCEL = 'Cancel',
    CLEAR = 'Clear',
    CLOSE = 'Close',
    CLOSE_IN_TIME = 'This message will close in ',
    CREW = 'crew',
    CUSTOM = 'Custom',
    DELETE = 'Delete',
    EDIT = 'Edit',
    EPIC = 'Your have an epic crew',
    ERROR = 'Error',
    ERROR_RETRY = 'Oops! Sorry, try again.',
    ERROR_SPECIAL_CHARACTERS = 'Only text and numbers.',
    ERROR_URL = 'Only valid URL.',
    FETCH_ERROR_USER = 'User fetch failed',
    GIGAWATT = '1.21 gigawatts!',
    HELLO_THERE = 'Hello there crew member',
    HEY_NOW = 'Hey crew',
    HOMEPAGE = 'Homepage',
    INTERNET_DOWN = 'No internet connection',
    I_AM = 'I am',
    LABEL_OPTIONAL = 'optional',
    LABEL_TITLE = 'Custom title',
    LABEL_URL = 'URL',
    LINK_ADD = 'Add link',
    LINK_ADD_MORE = 'Add more links',
    LINK_ADD_QUESTION = 'Wanna add some links?',
    LINK_ADD_SUCCESS = 'Link has been successfully added.',
    LINK_DELETE_CONFIRM = 'Are you sure you want to delete this link?',
    LINK_DELETE_SUCCESS = 'The link has been successfully deleted.',
    LINK_SUB_TYPE = 'Service',
    LINK_TYPE = 'Link type',
    LOADING = 'Loading...',
    LOGGED_OUT = 'Bye! See you soon!',
    LOGIN = 'Log in',
    LOGIN_SSO_NOTE = 'Secure login with SSO',
    LOG_OUT = 'Log out',
    MESSENGER = 'Messenger',
    MODAL_TITLE = 'Modal',
    MY_CREW = 'myCrew',
    MY_PROFILE = 'My Profile',
    NETWORK_ERROR = 'Network fetch error',
    NO_RESULTS = 'Oops, no results...',
    OK = 'OK',
    OPENING_SSO = 'Logging in via SSO',
    OVERLAY_ALL_TEAMS_TITLE = 'Viewing all teams',
    PHOTO_ADD = 'Add photo',
    PHOTO_ADD_DRAG = "Drag 'n' drop or click to select.",
    PHOTO_ADD_DRAGGING = 'Drop the photo here...',
    PHOTO_ADD_DRAG_NOTE = 'Only JPEG or PNG format is valid. n/Maximum size 16 MB. n/Minimal dimensions 250x250 px.',
    PHOTO_ADD_NOTICE_SIZE = 'Photo is larger than 6 MB. Upload might be slow.',
    PHOTO_ADD_NOTICE_SIZE_DANGER = 'Photo is close to 16 MB. Upload will be slow.',
    PHOTO_ADD_NOTICE_SIZE_ERROR = 'Photo is greater than 16 MB. Upload might fail.',
    PHOTO_CHANGE = 'Change photo',
    PHOTO_CROP_CONFIRM = 'Crop photo',
    PHOTO_DELETE = 'Delete photo',
    PHOTO_EDIT = 'Edit photo',
    PHOTO_SAVE = 'Keep photo',
    PHOTO_SELECT = 'Select photo',
    PLEASE_WAIT = 'Please wait',
    PROBLEM = 'Oops, it seems there was a problem.',
    PROCESSING = 'Processing the request',
    PROFILE_PHOTO = 'profile photo',
    PSST = 'Psst, hey ',
    READJUST = 'Readjust',
    REDO = 'Redo',
    REPOSITORY = 'Repository',
    REQUEST = 'Requesting...',
    SAVE = 'Save',
    SAVING = 'Saving...',
    SEARCH = 'Search',
    SEARCH_PLACEHOLDER = 'Find people or teams',
    SEARCH_PLACEHOLDER_SHORTCUT = 'CTRL+F to search',
    SESSION_EXPIRED = 'Session has expired',
    SHOW_ALL = 'Show all',
    SOCIAL = 'Social',
    SUCCESS = 'Success',
    SUPPORT = 'Support',
    SWITCH_TO = 'Switch to',
    TEAM = 'Team',
    TEAM_ABOUT = 'General team info',
    TEAM_HAS = 'team has',
    TEAM_MEMBERS = 'members',
    THAT_WAS_FUN = 'That was a fun!',
    THEME_DARK = 'Dark theme',
    THEME_LIGHT = 'Light theme',
    TRY_AGAIN = 'Maybe try again?',
    URL_PLACEHOLDER = 'https://www...',
    USER_MENU = 'User menu',
    VIEW = 'View ',
    VIEW_ALL = 'View all',
    WELCOME = 'Welcome, ',
    WELCOME_BACK = 'Welcome back',
    WICKED = 'Wicked cool crew!',
    YOU_ARE_IN = 'You are in the crew!'
}

export enum LinkTypeName {
    BLOG = 'Blog',
    HOMEPAGE = 'Homepage',
    PORTFOLIO = 'Portfolio',
    REPOSITORY_BITBUCKET = 'BitBucket',
    REPOSITORY_GITHUB = 'GitHub',
    REPOSITORY_GITLAB = 'GitLab',
    REPOSITORY_SOURCE_FORGE = 'SourceForge',
    SOCIAL_INSTAGRAM = 'Instagram',
    SOCIAL_FACEBOOK = 'Facebook',
    SOCIAL_LINKEDIN = 'LinkedIn',
    SOCIAL_MEET_UP = 'Meetup',
    SOCIAL_REDDIT = 'Reddit',
    SOCIAL_TWITTER = 'Twitter'
}

export enum LinkTypeLabel {
    CUSTOM = 'custom',
    HOMEPAGE = 'homepage',
    MESSENGER = 'messenger',
    REPOSITORY = 'repository',
    SOCIAL = 'social'
}

export enum RegExp {
    URL = '/^(ftp|http|https):\\/\\/[^ "]+$/',
    TITLE = '^\\s*[a-zA-Z,\\s]+\\s*$'
}

export enum Cookie {
    THEME = 'theme'
}

export enum Theme {
    DARK = 'dark',
    LIGHT = 'light'
}

export enum ColorType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

export enum SizeVariant {
    DEFAULT = 'default',
    LG = 'large',
    MD = 'medium',
    SM = 'small',
    XL = 'extra-large'
}

export enum Session {
    AUTH = 'mca',
    CURRENT_USER = 'mccu',
    LOGIN_USER = 'mclu'
}

export enum Memory {
    SIZE_MB_6 = 6291456,
    SIZE_MB_12 = 12582912,
    SIZE_MB_15 = 15728640,
    SIZE_MB_16 = 16777216
}

export enum Environment {
    PRODUCTION = 'production'
}

export enum TabItem {
    HOME = 'home',
    MY_PROFILE = 'my-profile',
    SEARCH = 'search',
    TEAM = 'team',
    USER = 'user',
}
