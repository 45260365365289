import React, { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { IRootStore } from 'interfaces/common';
import {loginEject, loginFailed, loginStart} from 'state/actions/LoginActions';

import LoginPage from 'components/pages/LoginPage/LoginPage';
import Pages from 'components/pages/Pages';

import Focus from 'components/molecules/Focus/Focus';
import Footer from 'components/molecules/Footer/Footer';
import Header from 'components/molecules/Header/Header';
import Notifications from 'components/molecules/Notifications/Notifications';
import TabBar from 'components/molecules/TabBar/TabBar';
import {useLocation} from "react-router-dom";

export const App = () => {
    const { isThemeLight } = useSelector((state: IRootStore) => state.common);
    const { isUserLoggedIn } = useSelector((state: IRootStore) => state.login);
    const location  = useLocation();
    const dispatch = useDispatch();

    const pageBody = document.querySelector('body');

    const myCrewLight = 'mycrew-light';
    const appClassName = `mycrew${isThemeLight ? ' ' + myCrewLight : ''}${
        !isUserLoggedIn && isDesktop ? ' login-ready' : ''
    }`;

    useEffect(() => {
      const query = new URLSearchParams(location.search);
      if (query.has('error')) {
        dispatch(loginFailed('Test'));
      } else if (query.has('logout')) {
        dispatch(loginEject());
      } else {
        dispatch(loginStart());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isThemeLight) {
            pageBody?.classList.add(myCrewLight);
        } else if (!isThemeLight && pageBody?.classList.contains(myCrewLight)) {
            pageBody?.classList.remove(myCrewLight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isThemeLight]);

    if (!isUserLoggedIn) {
        return (
            <div className={appClassName}>
                <Notifications />
                <LoginPage />
            </div>
        );
    }

    return (
        <div className={appClassName}>
            <Notifications />
            <Header />
            <Pages />
            <Footer />
            <TabBar />
            <Focus />
        </div>
    );
};

export default App;
