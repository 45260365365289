import React, {useEffect} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Link as RouterLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@material-ui/core/Link';
import { AssignmentInd, Brightness2, LockOpen, WbSunny } from '@material-ui/icons';

import API_CONFIG from 'api/apiConfig';

import { IRootStore } from 'interfaces/common';
import { Cookie, Session, Translation } from 'constants/CommonConstants';
import { setTheme } from 'state/actions/CommonActions';
import { getUserSelected } from 'state/actions/UserActions';

import { setModalClosed } from 'state/actions/ModalActions';
import { loginEject } from 'state/actions/LoginActions';
import OverlayContext from 'components/molecules/Overlay/context/OverlayContext';

import 'components/pages/UserPage/menu/UserMenu.scss';

export const UserMenu = () => {
    const { currentUser } = useSelector((state: IRootStore) => state.user);
    const { isThemeLight } = useSelector((state: IRootStore) => state.common);
    const [cookies, setCookie] = useCookies([Cookie.THEME]);

    const dispatch = useDispatch();

    const classBase = 'mycrew-team-user-menu';

    useEffect(() => {
        if (cookies.theme && cookies.theme === 'true') {
            dispatch(setTheme(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        dispatch(setModalClosed());
        currentUser && currentUser.id && dispatch(getUserSelected(currentUser.id));
    };

    const toggleTheme = () => {
        setCookie(Cookie.THEME, !isThemeLight, { path: '/' });
        dispatch(setTheme(!isThemeLight));
        return null;
    };

    const handleLogOut = (event: React.SyntheticEvent) => {
        event.preventDefault();

        sessionStorage.removeItem(Session.AUTH);
        sessionStorage.removeItem(Session.CURRENT_USER);
        sessionStorage.removeItem(Session.LOGIN_USER);

        dispatch(loginEject());
    };

    const renderTeamOverview = () => {
        return (
            <div className={classBase}>
                <Scrollbars style={{ height: '100%' }}>
                    <li className={`${classBase}__list-item`}>
                        <Link
                            className={`${classBase}__list-link`}
                            color={'inherit'}
                            component={RouterLink}
                            onClick={() => handleClick()}
                            title={Translation.MY_PROFILE}
                            to={`/${API_CONFIG.USER}/${currentUser?.id}`}
                            underline="none"
                        >
                            <AssignmentInd className={`${classBase}__icon`} />
                            {Translation.MY_PROFILE}
                        </Link>
                    </li>
                    <li className={`${classBase}__list-item`}>
                        <span
                            className={`${classBase}__list-link`}
                            onClick={() => toggleTheme()}
                            title={isThemeLight ? Translation.THEME_LIGHT : Translation.THEME_DARK}
                        >
                            {isThemeLight ? <Brightness2 className={`${classBase}__icon`} /> : <WbSunny className={`${classBase}__icon`} /> }
                            {!isThemeLight ? Translation.THEME_LIGHT : Translation.THEME_DARK}
                        </span>
                    </li>
                    <li className={`${classBase}__list-item`}>
                        <Link
                            className={`${classBase}__list-link`}
                            color={'inherit'}
                            component={RouterLink}
                            onClick={(event: React.SyntheticEvent) => handleLogOut(event)}
                            title={Translation.LOG_OUT}
                            to={`/`}
                            underline="none"
                        >
                            <LockOpen className={`${classBase}__icon`} />
                            {Translation.LOG_OUT}
                        </Link>
                    </li>
                </Scrollbars>
            </div>
        );
    };

    return <OverlayContext content={renderTeamOverview()} title={Translation.USER_MENU} />;
};

export default UserMenu;
