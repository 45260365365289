import React from 'react';
import { Link } from '@material-ui/core';

import { Developer, Translation } from 'constants/CommonConstants';

import 'components/molecules/Footer/Footer.scss';

export const Footer = () => {
    const typoLogo = () => {
        const titleCharacters = Developer.TITLE.split('');

        return titleCharacters.map((letter, index) => {
            if (letter === ' ') {
                return <i key={letter}>{letter}</i>;
            }

            return (
                <span className={`brand-letter brand-letter__${index}`} key={letter}>
                    {letter}
                </span>
            );
        });
    };

    const copyYear = new Date().getFullYear();

    return (
        <div className="mycrew-footer">
            <div className="mycrew-footer__copy">
                <span className="brand">{typoLogo()}</span> &copy; {copyYear}
            </div>
            <div className="mycrew-footer__support">
                <Link className="mycrew-footer__support--link" href={`mailto:${Developer.EMAIL}`}>
                    {Translation.SUPPORT}
                </Link>
            </div>
        </div>
    );
};

export default Footer;
