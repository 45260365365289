import { ISearchState } from 'interfaces/common';
import { SEARCH_FOCUS, SearchActionTypes } from 'state/actions/SearchActionTypes';

const defaultState: ISearchState = {
    isSearchActive: false
};

const searchReducer = (state: ISearchState = defaultState, action: SearchActionTypes): ISearchState => {
    switch (action.type) {
        case SEARCH_FOCUS:
            return {
                ...state,
                isSearchActive: action.payload
            };

        default:
            return state;
    }
};

export default searchReducer;
