import { InfoOutlined } from '@material-ui/icons';
import Message from 'components/atoms/Message/Message';
import ModalContext from 'components/molecules/Modal/context/ModalContext';
import { ColorType, Memory, Translation } from 'constants/CommonConstants';
import { IPhotoFile, IRootStore } from 'interfaces/common';
import React, { useCallback, useState, createRef, useEffect } from 'react';
import Dropzone, { DropzoneRef, useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';

import 'components/pages/UserPage/photo/add/PhotoAdd.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setModalClosed } from 'state/actions/ModalActions';
import { addUserAvatar } from 'state/actions/UserActions';
import getCroppedImg from 'utils/helpers';

export const PhotoAdd: React.FunctionComponent = () => {
    const { selected } = useSelector((state: IRootStore) => state.user);

    const selectedUser = selected;
    const dispatch = useDispatch();

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [showNotice, setShowNotice] = useState('');
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState('');
    const [blobImage, setBlobImage] = useState<Blob>();
    const [previewPhoto, setPreviewPhoto] = useState<IPhotoFile[]>([]);
    const [zoom, setZoom] = useState(1);

    const dropzoneRef = createRef<any>();

    useEffect(() => {
        if (!!previewPhoto[0]) {
            renderNotice(previewPhoto[0].size);
        }
    }, [previewPhoto]);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        setPreviewPhoto(
            acceptedFiles.map((photo: File) =>
                Object.assign(photo, {
                    preview: URL.createObjectURL(photo)
                })
            )
        );

        return null;
    }, []);

    const { isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png',
        maxFiles: 1,
        minSize: 300
    });

    const acceptedPhotoPreview = () => {
        const userPhoto = previewPhoto[0];

        if (!userPhoto) {
            return null;
        }

        return (
            <div className="photo-add__cropper" key={userPhoto.size}>
                <Cropper
                    image={previewPhoto[0]?.preview}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
        );
    };

    const showCroppedImage = useCallback(async () => {
        let parsedBlob = await getCroppedImg(previewPhoto[0].preview, previewPhoto[0].type, croppedAreaPixels, 0);

        if (parsedBlob) {
            setBlobImage(parsedBlob.blob);
            setCroppedImage(parsedBlob?.preview);
        }
    }, [croppedAreaPixels, previewPhoto]);

    const handleCancel = () => {
        if (previewPhoto.length !== 0 && !croppedImage) {
            setPreviewPhoto([]);
            setShowNotice('');
            return null;
        }

        if (croppedImage) {
            setCroppedImage('');
            return null;
        }

        dispatch(setModalClosed());
        return null;
    };

    const cancelText = () => {
        if (previewPhoto.length !== 0 && !croppedImage) {
            return Translation.BACK;
        }

        if (croppedImage) {
            return Translation.READJUST;
        }

        return Translation.CANCEL;
    };

    const handleConfirm = () => {
        if (!selectedUser) {
            return null;
        }

        if (dropzoneRef && dropzoneRef.current !== null) {
            return dropzoneRef.current.open();
        }

        if (croppedImage && selectedUser.id) {
            dispatch(addUserAvatar(selectedUser.id, blobImage));
        }

        if (previewPhoto) {
            return showCroppedImage();
        }

        return null;
    };

    const confirmText = () => {
        if (croppedImage) {
            return Translation.PHOTO_SAVE;
        }

        if (previewPhoto.length !== 0) {
            return Translation.PHOTO_CROP_CONFIRM;
        }

        return Translation.PHOTO_SELECT;
    };

    const renderNotice = (noteSize: number) => {
        if (noteSize > Memory.SIZE_MB_12) {
            if (noteSize < Memory.SIZE_MB_16) {
                return setShowNotice(Translation.PHOTO_ADD_NOTICE_SIZE_DANGER);
            }

            return setShowNotice(Translation.PHOTO_ADD_NOTICE_SIZE_ERROR);
        }

        if (noteSize > Memory.SIZE_MB_6) {
            return setShowNotice(Translation.PHOTO_ADD_NOTICE_SIZE);
        }
    };

    const renderContent = () => {
        if (croppedImage) {
            return (
                <div className="mycrew-modal__content-block">
                    <div className="photo-add__preview">
                        <img className="photo-add__preview-photo" alt={Translation.PROFILE_PHOTO} src={croppedImage} />
                    </div>
                </div>
            );
        }

        if (previewPhoto.length !== 0) {
            return (
                <div className="mycrew-modal__content-block">
                    <div className="photo-add__cropper">{acceptedPhotoPreview()}</div>
                </div>
            );
        }

        return (
            <Dropzone ref={dropzoneRef as React.RefObject<DropzoneRef>} onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="mycrew-modal__content-block">
                        <div className="photo-add">
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <span className="photo-add__drop photo-add__drop--dragging">
                                    <p className="photo-add__drop-text">{Translation.PHOTO_ADD_DRAGGING}</p>
                                </span>
                            ) : (
                                <span className="photo-add__drop">
                                    <p className="photo-add__drop-text">
                                        {Translation.PHOTO_ADD_DRAG}
                                        <span className="photo-add__drop-text--note">
                                            <InfoOutlined className="photo-add__drop-text--note-icon" />
                                            <Message text={Translation.PHOTO_ADD_DRAG_NOTE} />
                                        </span>
                                    </p>
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    };

    return (
        <ModalContext
            content={renderContent()}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            showNotice={showNotice}
            textCancel={cancelText()}
            textConfirm={confirmText()}
            theme={ColorType.PRIMARY}
            title={Translation.PHOTO_ADD}
        />
    );
};

export default PhotoAdd;
