import ModalBody from 'components/molecules/Modal/body/ModalBody';
import ModalFooter from 'components/molecules/Modal/footer/ModalFooter';
import ModalHeader from 'components/molecules/Modal/header/ModalHeader';
import ModalLoader from 'components/molecules/Modal/loader/ModalLoader';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IModalOverlayProps, IRootStore } from 'interfaces/common';

import { setModalClosed } from 'state/actions/ModalActions';

import { Translation } from 'constants/CommonConstants';

import 'components/molecules/Modal/context/ModalContext.scss';

export const ModalContext: React.FunctionComponent<IModalOverlayProps> = ({
    classSuffix,
    content,
    isErroneous = false,
    isSoloFooterOption,
    isSubmitDisabled,
    onCancel,
    onConfirm,
    showNotice,
    sizeVariant,
    textCancel = Translation.CANCEL,
    textConfirm = Translation.OK,
    theme = 'default',
    title = Translation.MODAL_TITLE
}) => {
    const { selected } = useSelector((state: IRootStore) => state.user);
    const { isOpen, config, modalType } = useSelector((state: IRootStore) => state.modal);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    closeModal();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, isOpen]);

    if (!isOpen || !config || !modalType || !selected) {
        return null;
    }

    const closeModal = (event?: React.SyntheticEvent) => {
        if (event) {
            event.preventDefault();
        }

        return dispatch(setModalClosed());
    };

    const classBaseName = 'mycrew-modal';
    const classNameModal = `${classBaseName} ${classBaseName}--loading${
        isErroneous ? ' ' + classBaseName + '--error' : ''
    }${sizeVariant ? ' ' + classBaseName + sizeVariant : ''}`;

    return (
        <>
            <div className="mycrew-modal-curtain" onClick={(event: React.SyntheticEvent) => closeModal(event)} />
            <div className={classNameModal}>
                <div className="mycrew-modal__wrapper">
                    <ModalHeader title={title} />
                    <ModalBody content={content} classSuffix={classSuffix} />
                    <ModalFooter
                        isSoloFooterOption={isSoloFooterOption}
                        isSubmitDisabled={isSubmitDisabled}
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                        showNotice={showNotice}
                        textCancel={textCancel}
                        textConfirm={textConfirm}
                        theme={theme}
                    />
                    <ModalLoader />
                </div>
            </div>
        </>
    );
};

export default ModalContext;
