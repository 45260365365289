import apiGo from 'api/api';
import API_CONFIG from 'api/apiConfig';

import {ISSOProfileObject} from 'interfaces/common';

import {Dispatch} from 'redux';

import {
    ILoginReset,
    LOGIN_EJECT,
    LOGIN_FAIL, LOGIN_RESET,
    LOGIN_SET_USER,
    LOGIN_START,
    LOGIN_SUCCESS,
    LoginActionTypes
} from 'state/actions/LoginActionsTypes';
import {IUserSetCurrent, USER_SET_CURRENT} from "./UserActionTypes";

export const loginEject = () => async (dispatch: Dispatch<LoginActionTypes>) => {
    dispatch({
        type: LOGIN_EJECT
    });
};

export const loginUser = (profileObject: ISSOProfileObject) => async (dispatch: Dispatch<LoginActionTypes>) => {
    dispatch({
        type: LOGIN_SET_USER,
        payload: profileObject
    });
};

export const loginFailed = (error: string) => async (dispatch: Dispatch<LoginActionTypes>) => {
    dispatch({
        type: LOGIN_FAIL,
        payload: error
    });
};

export const loginReset = (): ILoginReset => ({
    type: LOGIN_RESET
});

export const loginStart = () => async (dispatch: Dispatch<LoginActionTypes| IUserSetCurrent>) => {
    dispatch({
        type: LOGIN_START,
    });
    return apiGo.get(API_CONFIG.WHOAMI).then((response) => {
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                role: response.data.role,
            },
        });
        dispatch({
            type: LOGIN_SET_USER,
            payload: response.data,
        });
        dispatch({
            type: USER_SET_CURRENT,
            payload: response.data,
        })
    }).catch(err => {
        dispatch(loginReset());
    });
}
