import React from 'react';
import { useDispatch } from 'react-redux';

import { IModalOverlayProps } from 'interfaces/common';
import { Translation } from 'constants/CommonConstants';

import { setModalClosed } from 'state/actions/ModalActions';

import { Link } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import 'components/molecules/Overlay/header/OverlayHeader.scss';

export const OverlayHeader: React.FunctionComponent<IModalOverlayProps> = ({ title }) => {
    const dispatch = useDispatch();

    const closeOverlay = (event: React.SyntheticEvent) => {
        event.preventDefault();
        return dispatch(setModalClosed());
    };

    return (
        <div className="mycrew-overlay-header">
            <h3 className="mycrew-overlay-header__title">{title ? title : Translation.LABEL_TITLE}</h3>
            <Link
                className="mycrew-overlay-header__close"
                href={`#${Translation.CLEAR}`}
                onClick={(event: React.SyntheticEvent) => closeOverlay(event)}
                title={Translation.CLEAR}
                underline="none"
            >
                <Close className="mycrew-overlay-header__close-icon" />
            </Link>
        </div>
    );
};

export default OverlayHeader;
