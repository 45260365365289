import React, { useEffect } from 'react';
// import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { IUser, IRootStore } from 'interfaces/common';

import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import { getDirectoryUsers } from 'state/actions/DirectoryActions';

import Loading from 'components/svgs/32/Loading';

import API_CONFIG from 'api/apiConfig';
import { Defaults, Translation } from 'constants/CommonConstants';

import { scrollTop } from 'utils/helpers';

import colors from 'styles/_colors.module.scss';
import 'components/pages/DirectoryPage/DirectoryPage.scss';

export const DirectoryPage = () => {
    const dispatch = useDispatch();
    const directoryState = useSelector((state: IRootStore) => state.directory);
    const { users } = directoryState;
    const isShowcaseUsers = users?.length === 0;

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        dispatch(getDirectoryUsers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _renderUserPhoto = (user: IUser) => {
        const { id, name } = user;

        return (
            <Link
                component={RouterLink}
                to={`/${API_CONFIG.USER}/${id}`}
                className="mycrew-directory-page__link loading"
            >
                <img src={`${Defaults.URL}/users/${id}.png`} alt={`Avatar of ${name}`} title={name} width="100%" />
                <span>
                    <strong>{name}</strong>
                </span>
            </Link>
        );
    };

    const _renderUserGridPhotos = (ofLarge: number, ofMedium: number, ofSmall: number) => {
        if (isShowcaseUsers) {
            return <div>{Translation.PROBLEM}</div>;
        }

        if (!ofLarge || !ofMedium || !ofSmall) {
            return (
                <span>
                    <Loading className="spinner" iconColor={colors.colorBrandPrimary} />
                </span>
            );
        }

        const classBase = 'bflex-item size-';
        const miniGrid: (JSX.Element | null)[] = [];

        const _renderUserPhotoItem = (user: IUser, userIndex: number) => {
            if (!user.id) {
                return null;
            }

            const classUserPhoto = `${classBase}${
                userIndex < ofLarge ? 'large' : userIndex++ < ofLarge + ofMedium ? 'medium' : 'micro'
            }`;

            return (
                <div className={classUserPhoto} key={user.id}>
                    {_renderUserPhoto(user)}
                </div>
            );
        };

        const userDataSet = users?.slice(0, ofLarge + ofMedium + ofSmall) || [];

        const mainGrid = userDataSet.map((user, index) => {
            if (index > ofLarge + ofMedium - 1) {
                miniGrid.push(_renderUserPhotoItem(user, index));
                return null;
            }

            return _renderUserPhotoItem(user, index);
        });

        return (
            <>
                {mainGrid}
                <div className={`${classBase}mini`}>{miniGrid}</div>
            </>
        );
    };

    return (
        <div className="mycrew-directory-page">
            <div className="bflex">{_renderUserGridPhotos(1, 5, 4)}</div>
        </div>
    );
};

export default DirectoryPage;
