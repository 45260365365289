import React from 'react';
import { useDispatch } from 'react-redux';

import { setModalClosed } from 'state/actions/ModalActions';

import { Button, PropTypes } from '@material-ui/core';

import 'components/molecules/Modal/footer/ModalFooter.scss';

interface IProps {
    isErroneous?: boolean;
    isSoloFooterOption?: boolean;
    isSubmitDisabled?: boolean;
    onCancel?(): (() => Promise<any>) | null;
    onConfirm?(): (() => Promise<any>) | null;
    showNotice?: string;
    textCancel?: string;
    textConfirm: string;
    theme: PropTypes.Color;
}

export const ModalFooter: React.FunctionComponent<IProps> = ({
    isErroneous,
    isSoloFooterOption = false,
    isSubmitDisabled = false,
    onCancel,
    onConfirm,
    showNotice,
    textCancel,
    textConfirm,
    theme
}) => {
    const dispatch = useDispatch();

    const renderModalNotice = () => {
        if (!showNotice) {
            return null;
        }

        return <div className="mycrew-modal__footer-notice">{showNotice}</div>;
    };

    const closeModal = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (!!onCancel) {
            onCancel();
            return null;
        }

        return dispatch(setModalClosed());
    };

    const confirmModal = (event: React.SyntheticEvent) => {
        if (isSoloFooterOption) {
            closeModal(event);
        }

        if (!onConfirm) {
            return null;
        }

        event.preventDefault();
        onConfirm();
        return null;
    };

    if (!textCancel || isSoloFooterOption) {
        return (
            <div className="mycrew-modal__footer">
                <Button
                    className={`dropdown-button${theme && ' dropdown-button__' + theme}`}
                    variant="contained"
                    disabled={isErroneous}
                    disableElevation={true}
                    color={theme}
                    onClick={(event: React.SyntheticEvent) => confirmModal(event)}
                >
                    {textConfirm}
                </Button>
            </div>
        );
    }

    return (
        <div className="mycrew-modal__footer">
            {renderModalNotice()}
            <Button
                className="dropdown-button dropdown-button__cancel"
                onClick={(event: React.SyntheticEvent) => closeModal(event)}
            >
                {textCancel}
            </Button>
            <Button
                className={`dropdown-button${theme && ' dropdown-button__' + theme}`}
                variant="contained"
                disabled={isSubmitDisabled}
                disableElevation={true}
                color={theme}
                onClick={(event: React.SyntheticEvent) => confirmModal(event)}
            >
                {textConfirm}
            </Button>
        </div>
    );
};

export default ModalFooter;
