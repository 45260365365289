import { ISSOProfileObject } from 'interfaces/common';

export const LOGIN_EJECT = 'LOGIN_EJECT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_SET_USER = 'LOGIN_SET_USER';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export interface ILoginEject {
    type: typeof LOGIN_EJECT;
}

export interface ILoginFail {
    type: typeof LOGIN_FAIL;
    payload: string;
}

export interface ILoginStart {
    type: typeof LOGIN_START;
}

export interface ILoginReset {
    type: typeof LOGIN_RESET;
}

export interface ILoginSuccess {
    type: typeof LOGIN_SUCCESS;
    payload: {
        role?: string;
    };
}

export interface ILoginUser {
    type: typeof LOGIN_SET_USER;
    payload: ISSOProfileObject;
}

export type LoginActionTypes =
    | ILoginEject
    | ILoginFail
    | ILoginReset
    | ILoginStart
    | ILoginSuccess
    | ILoginUser;
