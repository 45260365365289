import React from 'react';
import { isDesktop } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Lock } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import BrandImageA from 'assets/login/brand-00.jpg';
import BrandImageB from 'assets/login/brand-01.jpg';
import BrandImageC from 'assets/login/brand-02.jpg';
import BrandImageD from 'assets/login/brand-03.jpg';
import Logo from 'components/svgs/64/Logo';

import { Branding, Customer, Defaults, Developer, Translation } from 'constants/CommonConstants';

import colors from 'styles/_colors.module.scss';
import 'components/pages/LoginPage/LoginPage.scss';
import { getRandomIndex } from 'utils/helpers';
import {IRootStore} from "../../../interfaces/common";

export const LoginPage = () => {
    const brandImages = [BrandImageA, BrandImageB, BrandImageC, BrandImageD];
    const { isLoginStart } = useSelector((state: IRootStore) => state.login);

    if (isDesktop) {
        return (
            <div className="mycrew-login-page">
                <div className="mycrew-login-page__panel mycrew-login-page__panel-promo">
                    <h3 className="mycrew-login-page__promo-title">{Branding.TAG_LINE}</h3>
                    <p className="mycrew-login-page__promo-text">
                        <span className="mycrew-login-page__promo-text-content">{Branding.TAG_TEXT}</span>
                    </p>
                    <p className="mycrew-login-page__promo-text-follow">
                        <span className="mycrew-login-page__promo-text-content">{Branding.TAG_TEXT_FOLLOW}</span>
                    </p>
                    <div className="mycrew-login-page__promo-image">
                        <img src={getRandomIndex(brandImages)} alt={Branding.TAG_LINE} width={320} />
                    </div>
                </div>
                <div className="mycrew-login-page__panel mycrew-login-page__panel-brand">
                    <div className="mycrew-login-page__graphics">
                        <Logo iconColor={colors.colorThemeDark} iconSize={192} />
                    </div>
                </div>
                <div className="mycrew-login-page__panel mycrew-login-page__panel-sso">
                    <h1 className="mycrew-login-page__customer">{Customer.TITLE}</h1>
                    <Button
                        className="mycrew-login-page__button btn-grad"
                        disableElevation={true}
                        href={`${Defaults.URL}/auth/google`}
                        variant="contained"
                        disabled={isLoginStart}
                    >
                      {isLoginStart ? Translation.LOADING : Translation.LOGIN}
                    </Button>
                    <p className="mycrew-login-page__note">
                        {Translation.LOGIN_SSO_NOTE}
                        <Lock className="mycrew-login-page__note--icon" />
                    </p>
                </div>
                <div className="mycrew-login-page__panel mycrew-login-page__panel-copy">
                    <p className="mycrew-login-page__copyright">{Developer.COPYRIGHT}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="mycrew-login-page">
            <div className="mycrew-login-page__panel mycrew-login-page__panel--top">
                <div className="mycrew-login-page__panel-content">
                    <div className="mycrew-login-page__graphics">
                        <Logo iconColor={colors.colorWhite} iconSize={196} />
                    </div>
                    <h1 className="mycrew-login-page__customer">{Customer.TITLE}</h1>
                </div>
            </div>
            <div className="mycrew-login-page__panel mycrew-login-page__panel--bottom">
                <div className="mycrew-login-page__panel-content">
                    <Button
                      className="mycrew-login-page__button btn-grad"
                      disableElevation={true}
                      href={`${Defaults.URL}/auth/google`}
                      variant="contained"
                      disabled={isLoginStart}
                    >
                      {isLoginStart ? Translation.LOADING : Translation.LOGIN}
                    </Button>
                    <p className="mycrew-login-page__note">
                        {Translation.LOGIN_SSO_NOTE}
                        <Lock className="mycrew-login-page__note--icon" />
                    </p>
                </div>
                <p className="mycrew-login-page__copyright">{Developer.COPYRIGHT}</p>
            </div>
        </div>
    );
};

export default LoginPage;
