import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import { IRootStore } from 'interfaces/common';

import { Customer } from 'constants/CommonConstants';

import { getUsers } from 'state/actions/UserActions';
import { getTeams } from 'state/actions/TeamActions';

import Logo from 'assets/branding/meterio.png';
import 'components/molecules/Header/Branding/Branding.scss';

export const Branding = () => {
    const dispatch = useDispatch();
    const listOfUsers = useSelector((state: IRootStore) => state.user);
    const listOfTeams = useSelector((state: IRootStore) => state.team);
    const numberOfUsers = !listOfUsers.users ? 0 : listOfUsers.users?.length;
    const numberOfTeams = !listOfTeams.teams ? 0 : listOfTeams.teams?.length;

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getTeams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mycrew-all-branding">
            <Link
                className="mycrew-all-branding__logo"
                component={RouterLink}
                to="/"
                onClick={(event: any) => event.currentTarget.blur()}
            >
                <img src={Logo} width="56" height="56" alt={`Logo of ${Customer.TITLE}`} />
            </Link>
            <div className="mycrew-all-branding__text">
                <h1>{Customer.TITLE}</h1>
                <h4>
                    <span>{numberOfTeams}</span> Teams and <span>{numberOfUsers}</span> Users
                </h4>
            </div>
        </div>
    );
};

export default Branding;
