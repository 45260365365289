import { Dispatch } from 'redux';

import apiGo from 'api/api';
import API_CONFIG from 'api/apiConfig';

import { TEAM_LOADING, TEAM_SUCCESS, TEAM_FAIL, TEAM_SELECTED, TeamActionTypes } from 'state/actions/TeamActionTypes';

export const getTeams = () => async (dispatch: Dispatch<TeamActionTypes>) => {
    dispatch({
        type: TEAM_LOADING
    });

    return apiGo
        .get(API_CONFIG.TEAM)
        .then((response) => {
            dispatch({
                type: TEAM_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: TEAM_FAIL
            });
        });
};

export const setTeamsSelected = (teamName: string) => async (dispatch: Dispatch<TeamActionTypes>) => {
    dispatch({
        type: TEAM_SELECTED,
        payload: teamName
    });
};
