import React from 'react';

import AllSearch from 'components/molecules/Header/AllSearch/AllSearch';
import Branding from 'components/molecules/Header/Branding/Branding';
import TeamMenu from 'components/molecules/Header/TeamMenu/TeamMenu';
import UserPanel from 'components/molecules/Header/UserPanel/UserPanel';

import 'components/molecules/Header/Header.scss';

export const Header = () => {
    return (
        <header className="mycrew-header">
            <Branding />
            <UserPanel />
            <TeamMenu />
            <AllSearch />
        </header>
    );
};

export default Header;
