import { Dispatch } from 'redux';

import apiGo from 'api/api';
import API_CONFIG from 'api/apiConfig';

import {
    DIRECTORY_LOADING,
    DIRECTORY_SUCCESS,
    DIRECTORY_FAIL,
    DirectoryActionTypes
} from 'state/actions/DirectoryActionTypes';

export const getDirectoryUsers = () => async (dispatch: Dispatch<DirectoryActionTypes>) => {
    dispatch({
        type: DIRECTORY_LOADING
    });

    return apiGo
        .get(API_CONFIG.DIRECTORY)
        .then((response) => {
            dispatch({
                type: DIRECTORY_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: DIRECTORY_FAIL
            });
        });
};
