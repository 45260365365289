import {ILoginState} from 'interfaces/common';
import {DIRECTORY_FAIL, DirectoryActionTypes} from 'state/actions/DirectoryActionTypes';

import {
    LOGIN_EJECT,
    LOGIN_FAIL,
    LOGIN_RESET,
    LOGIN_SET_USER,
    LOGIN_START,
    LOGIN_SUCCESS,
    LoginActionTypes
} from 'state/actions/LoginActionsTypes';
import {TEAM_FAIL, TeamActionTypes} from 'state/actions/TeamActionTypes';
import {USER_FAIL, UserActionTypes} from 'state/actions/UserActionTypes';

const defaultState: ILoginState = {
    isLoginEjected: false,
    isLoginFail: false,
    isLoginStart: false,
    isLoginSuccess: false,
    isUserLoggedIn: false,
    loginUser: null,
    userRole: ''
};

const loginReducer = (
    state: ILoginState = defaultState,
    action: LoginActionTypes | DirectoryActionTypes | UserActionTypes | TeamActionTypes
): ILoginState => {
    switch (action.type) {
        case LOGIN_EJECT:
            return {
                ...defaultState,
                isLoginEjected: true
            };

        case LOGIN_RESET:
            return defaultState;

        case LOGIN_START:
            return {
                ...state,
                isLoginEjected: false,
                isLoginFail: false,
                isLoginStart: true,
                isLoginSuccess: false,
                isUserLoggedIn: false,
                loginUser: null,
                userRole: ''
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoginEjected: false,
                isLoginFail: false,
                isLoginStart: false,
                isLoginSuccess: true,
                isUserLoggedIn: true,
                userRole: action.payload.role
            };

        case LOGIN_SET_USER:
            return {
                ...state,
                isLoginEjected: false,
                loginUser: action.payload
            };

        case LOGIN_FAIL:
            return {
                ...state,
                isLoginFail: true,
                isLoginStart: false,
                isLoginSuccess: false,
                loginUser: null,
                userRole: ''
            };

        case USER_FAIL:
        case TEAM_FAIL:
        case DIRECTORY_FAIL:
            return {
                ...state,
                isLoginFail: true,
                userRole: ''
            };

        default:
            return state;
    }
};

export default loginReducer;
