import axios from 'axios';

import API_CONFIG from 'api/apiConfig';

export const apiGo = axios.create({
    baseURL: API_CONFIG.BASE_URL
});

apiGo.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        return config;
    },
    (error) => {
        console.error('Auth: ', error);
    }
);

export default apiGo;
