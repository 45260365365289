import React from 'react';
import { useSelector } from 'react-redux';

import { Translation } from 'constants/CommonConstants';
import { ModalType } from 'constants/PageConstants';

import { IRootStore } from 'interfaces/common';

import LinkAdd from 'components/pages/UserPage/links/add/LinkAdd';
import LinkDelete from 'components/pages/UserPage/links/delete/LinkDelete';
import LinkEdit from 'components/pages/UserPage/links/edit/LinkEdit';
import PhotoAdd from 'components/pages/UserPage/photo/add/PhotoAdd';
import PhotoDelete from 'components/pages/UserPage/photo/delete/PhotoDelete';
import PhotoEdit from 'components/pages/UserPage/photo/edit/PhotoEdit';
import TeamOverview from 'components/pages/TeamPage/overview/TeamOverview';
import UserMenu from 'components/pages/UserPage/menu/UserMenu';

import 'components/molecules/Focus/Focus.scss';

export const Focus: React.FunctionComponent = () => {
    const { isOpen, modalType } = useSelector((state: IRootStore) => state.modal);

    if (!isOpen) {
        return null;
    }

    switch (modalType) {
        case ModalType.LINK_ADD:
            return <LinkAdd />;
        case ModalType.LINK_DELETE:
            return <LinkDelete />;
        case ModalType.LINK_EDIT:
            return <LinkEdit />;
        case ModalType.OVERLAY_ALL_TEAMS:
            return <TeamOverview />;
        case ModalType.USER_MENU:
            return <UserMenu />;
        case ModalType.PHOTO_ADD:
            return <PhotoAdd />;
        case ModalType.PHOTO_DELETE:
            return <PhotoDelete />;
        case ModalType.PHOTO_EDIT:
            return <PhotoEdit />;
        default:
            return <div className="mycrew-modal__content">{Translation.NO_RESULTS}</div>;
    }
};

export default Focus;
