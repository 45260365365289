import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import Store from 'state/store';

import App from 'components/App';

import reportWebVitals from 'reportWebVitals';

import 'styles/index.scss';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={Store}>
            <CookiesProvider>
                <Router>
                    <App />
                </Router>
            </CookiesProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
