import { Translation } from 'constants/CommonConstants';
import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { IRootStore } from 'interfaces/common';

import { useSelector } from 'react-redux';

import 'components/molecules/Notifications/Notifications.scss';
import { getRandomSuccessMessage } from 'utils/helpers';

export const Notifications = () => {
    const { isLoginEjected, isLoginFail, isLoginStart, isLoginSuccess, loginUser } =
        useSelector((state: IRootStore) => state.login);
    const { userErrors } = useSelector((state: IRootStore) => state.user);

    useEffect(() => {
        toast.dismiss();

        if (isLoginEjected) {
            toast(Translation.LOGGED_OUT, {
                icon: '👋'
            });
        }

    }, [
        isLoginEjected,
        isLoginFail,
        isLoginStart,
        isLoginSuccess,
        loginUser?.givenName,
        userErrors
    ]);

    return <Toaster />;
};

export default Notifications;
