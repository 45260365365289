import { Dispatch } from 'redux';

import { THEME_CHANGE, CommonActionTypes } from 'state/actions/CommonActionTypes';

export const setTheme = (isThemeLight: boolean) => async (dispatch: Dispatch<CommonActionTypes>) => {
    dispatch({
        type: THEME_CHANGE,
        payload: isThemeLight
    });
};
