import { Environment } from 'constants/CommonConstants';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers/RootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const isProd = process.env.NODE_ENV === Environment.PRODUCTION;
const reduxDevOptions = { trace: true, traceLimit: 25 };
const composeEnhancers = composeWithDevTools(reduxDevOptions);
const thunkMiddle = applyMiddleware(thunk);

const Store = createStore(rootReducer, isProd ? thunkMiddle : composeEnhancers(thunkMiddle));

export default Store;
