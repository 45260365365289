import API_CONFIG from 'api/apiConfig';
import { Defaults, Translation } from 'constants/CommonConstants';
import { IRootStore, IUser } from 'interfaces/common';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { setTeamsSelected } from 'state/actions/TeamActions';

import { scrollTop } from 'utils/helpers';

import 'components/pages/TeamPage/TeamPage.scss';

export const TeamPage: React.FunctionComponent = () => {
    const listOfUsers = useSelector((state: IRootStore) => state.user.users);
    const selectedTeam = useSelector((state: IRootStore) => state.team.selected);

    const dispatch = useDispatch();

    const [teamMembersCount, setTeamMembersCount] = useState(0);
    const [teamMembers, setTeamMembers] = useState<IUser[]>([]);

    const currentLocation = window.location.pathname.split('/').pop()?.replace(/%20/g, ' ');

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        if (selectedTeam === '' && currentLocation) {
            dispatch(setTeamsSelected(currentLocation));
        }
    }, [dispatch, currentLocation, selectedTeam]);

    useEffect(() => {
        const allTeamMembers = listOfUsers?.filter((teamMember) => teamMember.team === selectedTeam) ?? [];

        if (allTeamMembers) {
            setTeamMembers(allTeamMembers);
            setTeamMembersCount(allTeamMembers.length);
        }
    }, [listOfUsers, selectedTeam]);

    const _renderTeamMembers = () => {
        return teamMembers.map((teamMember) => {
            const memberPhoto = teamMember.id ? `${Defaults.URL}/users/${teamMember.id}.png` : '';
            // TODO Not working on BE
            // const memberPhotoSmall = teamMember.id ? `${Defaults.URL}/users/${teamMember.id}-small.png` : '';

            return (
                <li key={teamMember.id} className="mycrew-team-page__members-item">
                    <Link
                        className="mycrew-team-page__members-person"
                        component={RouterLink}
                        to={`/${API_CONFIG.USER}/${teamMember.id}`}
                        underline="none"
                    >
                        <div className="mycrew-team-page__members-person-photo">
                            <img
                                alt={teamMember.name}
                                className="mycrew-team-page__members-person-photo--image loading"
                                // srcSet={`${memberPhotoSmall}, ${memberPhoto}`}
                                src={memberPhoto}
                            />
                        </div>
                        <div className="mycrew-team-page__members-person-position">{teamMember.position}</div>
                        <div className="mycrew-team-page__members-person-name">
                            <strong>{teamMember.name?.split(' ').shift()}</strong>
                            {teamMember.name?.split(' ').pop()}
                        </div>
                    </Link>
                </li>
            );
        });
    };

    return (
        <div className="mycrew-team-page">
            <h1 className="mycrew-team-page__title">
                <span>
                    {selectedTeam} {Translation.CREW}
                </span>
            </h1>
            {/*<div className="mycrew-team-page__about">*/}
            {/*    <h3>{Translation.TEAM_ABOUT}</h3>*/}
            {/*    <div>*/}
            {/*        <ul>*/}
            {/*            <li>*/}
            {/*                <h4>Team motto</h4>*/}
            {/*                <p>When all else fails, wrap it in a try/catch.</p>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <h4>Assigned projects</h4>*/}
            {/*                <p>*/}
            {/*                    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis*/}
            {/*                    egestas.*/}
            {/*                </p>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <h4>Product owner</h4>*/}
            {/*                <p>John Doe</p>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <h4>SCRUM master</h4>*/}
            {/*                <p>Jane Doe</p>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <h4>Location</h4>*/}
            {/*                <p>*/}
            {/*                    Building 1, Floor 2, Room 3<br />*/}
            {/*                    Belgrade, Serbia*/}
            {/*                </p>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <h4>Contact</h4>*/}
            {/*                <ul>*/}
            {/*                    <li>{selectedTeam?.toLowerCase()}-mail@example.com</li>*/}
            {/*                    <li>Slack #{selectedTeam?.toLowerCase()}-team</li>*/}
            {/*                    <li>Red phone +381 0800 100 100</li>*/}
            {/*                </ul>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="mycrew-team-page__members">
                <h2 className="mycrew-team-page__members-title">
                    {teamMembersCount} {Translation.TEAM_MEMBERS}
                </h2>
                <ul className="mycrew-team-page__members-list">{_renderTeamMembers()}</ul>
            </div>
        </div>
    );
};

export default TeamPage;
