import { ILink, IUser } from 'interfaces/common';

export const USER_FAIL = 'USER_FAIL';
export const USER_LINK_ADD_FAIL = 'USER_LINK_ADD_FAIL';
export const USER_LINK_ADD_LOADING = 'USER_LINK_ADD_LOADING';
export const USER_LINK_ADD_SUCCESS = 'USER_LINK_ADD_SUCCESS';
export const USER_LINK_DELETE_FAIL = 'USER_LINK_DELETE_FAIL';
export const USER_LINK_DELETE_LOADING = 'USER_LINK_DELETE_LOADING';
export const USER_LINK_DELETE_SUCCESS = 'USER_LINK_DELETE_SUCCESS';
export const USER_LINK_INDEX_LOADING = 'USER_LINK_INDEX_LOADING';
export const USER_LINK_INDEX_SUCCESS = 'USER_LINK_INDEX_SUCCESS';
export const USER_LOADING = 'USER_LOADING';
export const USER_PHOTO_ADD_FAIL = 'USER_PHOTO_ADD_FAIL';
export const USER_PHOTO_ADD_LOADING = 'USER_PHOTO_ADD_LOADING';
export const USER_PHOTO_ADD_SUCCESS = 'USER_PHOTO_ADD_SUCCESS';
export const USER_SELECTED_FAIL = 'USER_SELECTED_FAIL';
export const USER_SELECTED_LOADING = 'USER_SELECTED_LOADING';
export const USER_SELECTED_SUCCESS = 'USER_SELECTED_SUCCESS';
export const USER_SET_CURRENT = 'USER_SET_CURRENT';
export const USER_SUCCESS = 'USER_SUCCESS';

export interface IUserLoading {
    type: typeof USER_LOADING;
}

export interface IUserSuccess {
    type: typeof USER_SUCCESS;
    payload: Array<IUser>;
}

export interface IUserFail {
    type: typeof USER_FAIL;
    payload: any;
}

export interface IUserSelectedLoading {
    type: typeof USER_SELECTED_LOADING;
}

export interface IUserSelectedSuccess {
    type: typeof USER_SELECTED_SUCCESS;
    payload: IUser;
}

export interface IUserSelectedFail {
    type: typeof USER_SELECTED_FAIL;
    payload: any;
}

export interface IUserLinkAddLoading {
    type: typeof USER_LINK_ADD_LOADING;
    payload: boolean;
}

export interface IUserLinkAddSuccess {
    type: typeof USER_LINK_ADD_SUCCESS;
    payload: ILink[];
}

export interface IUserLinkAddFail {
    type: typeof USER_LINK_ADD_FAIL;
    payload: any;
}

export interface IUserLinkDeleteLoading {
    type: typeof USER_LINK_DELETE_LOADING;
}

export interface IUserLinkDeleteSuccess {
    type: typeof USER_LINK_DELETE_SUCCESS;
    payload: ILink[];
}

export interface IUserLinkDeleteFail {
    type: typeof USER_LINK_DELETE_FAIL;
    payload: any;
}

export interface IUserSelectedLinkIndexSuccess {
    type: typeof USER_LINK_INDEX_SUCCESS;
    payload: number;
}

export interface IUserPhotoAddLoading {
    type: typeof USER_PHOTO_ADD_LOADING;
    payload: any;
}

export interface IUserPhotoAddSuccess {
    type: typeof USER_PHOTO_ADD_SUCCESS;
    payload: any;
}

export interface IUserSetCurrent {
    type: typeof USER_SET_CURRENT;
    payload: IUser | undefined;
}

export interface IUserPhotoAddFail {
    type: typeof USER_PHOTO_ADD_FAIL;
    payload: any;
}

export type ExportTypes =
    | IUserFail
    | IUserLinkAddFail
    | IUserLinkAddLoading
    | IUserLinkAddSuccess
    | IUserLinkDeleteFail
    | IUserLinkDeleteLoading
    | IUserLinkDeleteSuccess
    | IUserLoading
    | IUserPhotoAddFail
    | IUserPhotoAddLoading
    | IUserPhotoAddSuccess
    | IUserSelectedFail
    | IUserSelectedLinkIndexSuccess
    | IUserSelectedLoading
    | IUserSelectedSuccess
    | IUserSetCurrent
    | IUserSuccess;

export type UserActionTypes = ExportTypes;
