export const MODAL_LOADING_START = 'MODAL_LOADING_START';
export const MODAL_LOADING_END = 'MODAL_LOADING_END';
export const MODAL_UPDATING_START = 'MODAL_UPDATING_START';
export const MODAL_UPDATING_END = 'MODAL_UPDATING_END';
export const MODAL_SET_OPEN = 'MODAL_SET_OPEN';
export const MODAL_SET_CLOSED = 'MODAL_SET_CLOSED';

export interface IModalLoadingStart {
    type: typeof MODAL_LOADING_START;
    payload?: {};
}

export interface IModalLoadingEnd {
    type: typeof MODAL_LOADING_END;
    payload?: {};
}

export interface IModalUpdatingStart {
    type: typeof MODAL_UPDATING_START;
    payload?: {};
}

export interface IModalUpdatingEnd {
    type: typeof MODAL_UPDATING_END;
    payload?: {};
}

export interface IModalSetOpen {
    type: typeof MODAL_SET_OPEN;
    payload: {};
}

export interface IModalSetClosed {
    type: typeof MODAL_SET_CLOSED;
    payload?: {};
}

export type ModalActionTypes =
    | IModalLoadingStart
    | IModalLoadingEnd
    | IModalUpdatingStart
    | IModalUpdatingEnd
    | IModalSetOpen
    | IModalSetClosed;
