import { ITeamState } from 'interfaces/common';
import { TEAM_FAIL, TEAM_LOADING, TEAM_SELECTED, TEAM_SUCCESS, TeamActionTypes } from 'state/actions/TeamActionTypes';

const defaultState: ITeamState = {
    loading: false,
    selected: '',
    teams: []
};

const teamReducer = (state: ITeamState = defaultState, action: TeamActionTypes): ITeamState => {
    switch (action.type) {
        case TEAM_LOADING:
            return {
                ...state,
                loading: true
            };

        case TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                teams: action.payload
            };

        case TEAM_FAIL:
            return {
                ...state,
                loading: false
            };

        case TEAM_SELECTED:
            return {
                ...state,
                selected: action.payload
            };

        default:
            return state;
    }
};

export default teamReducer;
