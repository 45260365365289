import { combineReducers } from 'redux';

import commonReducer from 'state/reducers/CommonReducer';
import directoryReducer from 'state/reducers/DirectoryReducer';
import loginReducer from 'state/reducers/LoginReducer';
import modalReducer from 'state/reducers/ModalReducer';
import searchReducer from 'state/reducers/SearchReducer';
import teamReducer from 'state/reducers/TeamReducer';
import usersReducer from 'state/reducers/UserReducer';

const rootReducer = combineReducers({
    common: commonReducer,
    directory: directoryReducer,
    login: loginReducer,
    modal: modalReducer,
    team: teamReducer,
    user: usersReducer,
    search: searchReducer
});

export default rootReducer;
