import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContentState, Editor, EditorState } from 'draft-js';

import { Defaults, Translation, UserRole } from 'constants/CommonConstants';
import { ModalType } from 'constants/PageConstants';

import { IRootStore, ILink, ILinkEvent, IUser } from 'interfaces/common';
import { setTeamsSelected } from 'state/actions/TeamActions';

import { getUserSelected, patchUserSelected, setUserSelectedLinkIndex } from 'state/actions/UserActions';
import { setModalOpen } from 'state/actions/ModalActions';

import Link from '@material-ui/core/Link';

import { CheckCircleOutline, Close, DoneOutline, Edit, ErrorOutline, AddCircleOutline } from '@material-ui/icons';

import { scrollTop } from 'utils/helpers';
import Loading from 'components/svgs/32/Loading';

import 'draft-js/dist/Draft.css';
import colors from 'styles/_colors.module.scss';
import 'components/pages/UserPage/UserPage.scss';

export const UserPage = ({ match }: any) => {
    const { currentUser, selected, loading } = useSelector((state: IRootStore) => state.user);
    const { loginUser } = useSelector((state: IRootStore) => state.login);

    const dispatch = useDispatch();
    const history = useHistory();

    const isUser = false;
    const editorRef = useRef<HTMLParagraphElement>(null);
    const isCurrentUser = loginUser?.email === selected?.email;
    const isUserAdmin = currentUser?.role === UserRole.ADMIN;

    const [aboutTextCounter, setAboutTextCounter] = useState<number>(0);
    const [editorText, setEditorText] = useState<any>(() => EditorState.createEmpty());
    const [isEditorActive, setEditorActive] = useState<boolean>(false);
    const [isEditorSaving, setIsEditorSaving] = useState<boolean>(false);
    const [userHasPhoto, setUserHasPhoto] = useState<boolean>(false);

    const refUserPage = useRef(null);

    const aboutClassName = `mycrew-user-page__about${isEditorActive ? ' editor-active' : ''}`;
    const editorBaseClass = 'mycrew-user-page__about-editor';
    const editorClassName = `${editorBaseClass}${isEditorActive ? ` ${editorBaseClass}--active` : ''}`;
    const getEditorText = editorText.getCurrentContent().getPlainText();
    const userName = selected?.name?.split(' ')[0];
    const userPhoto = selected?.id ? `${Defaults.URL}/users/${selected.id}.png?${Date.now()}` : '';

    useEffect(() => {
        scrollTop(true, true);
    }, []);

    useEffect(() => {
        if (!loading) {
            dispatch(getUserSelected(match.params.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        resetEditor();
        _renderUserPhoto();

        if (!loading) {
            setIsEditorSaving(false);
        }

        if (userPhoto) {
            setUserHasPhoto(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        _updateCounter(getEditorText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorText]);

    useEffect(() => {
        // TODO SET FOCUS ON EDITOR
    }, [editorRef, isEditorActive]);

    // TODO 3G FAST LOADER
    if (!selected) {
        return (
            <span>
                <Loading className="spinner" iconColor={colors.colorBrandPrimary} />
            </span>
        );
    }

    const resetEditor = () => {
        if (selected && selected.about) {
            setEditorText(EditorState.createWithContent(ContentState.createFromText(selected.about)));
        }
    };

    const _updateCounter = (textBlock: string | null) => setAboutTextCounter(textBlock?.length ? textBlock?.length : 0);

    const _renderCharCounter = () => {
        if (!isEditorActive) {
            return null;
        }

        const isCountOk = aboutTextCounter > Defaults.ABOUT_TEXT_MAX_ALLOWED;
        const iconClassName = `mycrew-user-page__about-counter--icon ${isCountOk ? 'error' : 'ok'}`;
        const showIcon = isCountOk ? (
            <ErrorOutline className={iconClassName} />
        ) : (
            <CheckCircleOutline className={iconClassName} />
        );

        return (
            <div className="mycrew-user-page__about-counter">
                {`${aboutTextCounter} / ${Defaults.ABOUT_TEXT_MAX_ALLOWED}`}
                <span className="mycrew-user-page__about-counter--icon">{showIcon}</span>
            </div>
        );
    };

    const handleEditSave = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (selected.id && isEditorActive) {
            setEditorActive(false);
            setIsEditorSaving(true);
            return dispatch(patchUserSelected(selected.id, { about: getEditorText }));
        }

        return setEditorActive(!isEditorActive);
    };

    const handleCancel = (event: React.SyntheticEvent) => {
        event.preventDefault();
        resetEditor();
        setEditorActive(false);
    };

    const _renderEditorButtons = () => {
        if (!isCurrentUser && !isUserAdmin) {
            return null;
        }

        // TODO Hide buttons if !isEditorActive and/or !isUserOrAdmin
        const _renderCancelButton = () => {
            if (!isEditorActive) {
                return null;
            }

            return (
                <button onClick={(event) => handleCancel(event)} className="mycrew-user-page__about-cancel">
                    {Translation.CANCEL}
                    <Close className="mycrew-user-page__about-cancel--icon" />
                </button>
            );
        };

        const getEditSaveButtonLabel = () => {
            if (isEditorSaving) {
                return Translation.SAVING;
            }

            if (isEditorActive) {
                return Translation.SAVE;
            }

            return Translation.EDIT;
        };

        const getEditSaveButtonIcon = () => {
            if (isEditorSaving) {
                return <Loading className="mycrew-user-page__about-edit--icon spinner" iconColor={colors.colorWhite} />;
            }

            if (isEditorActive) {
                return <DoneOutline className="mycrew-user-page__about-edit--icon" />;
            }

            return <Edit className="mycrew-user-page__about-edit--icon" />;
        };

        return (
            <>
                <button
                    className="mycrew-user-page__about-edit"
                    disabled={aboutTextCounter > Defaults.ABOUT_TEXT_MAX_ALLOWED}
                    onClick={handleEditSave}
                >
                    {getEditSaveButtonLabel()}
                    {getEditSaveButtonIcon()}
                </button>
                {_renderCancelButton()}
            </>
        );
    };

    const _renderModal = (event: ILinkEvent | React.SyntheticEvent, modalType: string, selectedLinkIndex?: number) => {
        event.preventDefault();

        if (selectedLinkIndex !== undefined) {
            dispatch(setUserSelectedLinkIndex(selectedLinkIndex));
        }

        return dispatch(setModalOpen(modalType));
    };

    const _renderTeamCreds = () => {
        if (!selected.team) {
            return null;
        }

        return (
            <small>
                {`<${selected.position}>`}
                <br />
                {Translation.ASSIGNED_TO}
                <Link
                    className="mycrew-user-page__creds-team-link"
                    href={`/team/${selected.team}`}
                    onClick={(event: React.SyntheticEvent) => handleTeamView(event)}
                    title={`${selected.team} ${Translation.CREW}`}
                    underline="none"
                >
                    {selected.team}
                </Link>
            </small>
        );
    };

    const _renderUserLinkItem = (linkData: ILink, selectedLinkIndex: number) => {
        const { url, title, type } = linkData;

        if (!url || !title || !type) {
            return null;
        }

        const _renderEditDeleteButtons = () => {
            if (!isCurrentUser && !isUserAdmin) {
                return null;
            }

            return (
                <>
                    <Link
                        className="external-item__button external-item__button--delete"
                        onClick={(event: React.SyntheticEvent) =>
                            _renderModal(event, ModalType.LINK_DELETE, selectedLinkIndex)
                        }
                    >
                        <Close className="external-item__button--icon" />
                    </Link>
                    {/*<Link*/}
                    {/*    className="external-item__button external-item__button--edit"*/}
                    {/*    onClick={(event: React.SyntheticEvent) =>*/}
                    {/*        _renderModal(event, ModalType.LINK_EDIT)*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Edit className="external-item__button--icon external-item__button--edit-icon" />*/}
                    {/*</Link>*/}
                </>
            );
        };

        return (
            <li className="mycrew-user-page__external-item" key={title}>
                <span className="external-item__label">
                    {type}
                    {_renderEditDeleteButtons()}
                </span>
                <Link
                    href={url.startsWith('http') ? url : `https://${url}`}
                    className="external-item__link"
                    title={title}
                >
                    {title}
                </Link>
            </li>
        );
    };

    const _renderUserLinkAddButton = () => {
        if (!isCurrentUser && !isUserAdmin) {
            return null;
        }

        const addLinkButtonTitle = selected?.links?.length !== 0 ? Translation.LINK_ADD_MORE : Translation.LINK_ADD;

        return (
            <li className="mycrew-user-page__external-add">
                <Link
                    className="mycrew-user-page__external-link mycrew-user-page__external-add--button"
                    title={addLinkButtonTitle}
                    onClick={(event: ILinkEvent) => _renderModal(event, ModalType.LINK_ADD)}
                >
                    <AddCircleOutline className="button-icon" />
                    {addLinkButtonTitle}
                </Link>
            </li>
        );
    };

    const _renderUserLinkItems = () => {
        const { links }: IUser = selected;

        if (!links || !Array.isArray(links)) {
            if (!isUser && isCurrentUser) {
                return (
                    <p className="mycrew-user-page__external-empty">
                        {Translation.PSST}
                        {userName}!
                        <br />
                        {Translation.LINK_ADD_QUESTION}
                    </p>
                );
            }

            return <h3 className="mycrew-user-page__external-empty">{userName} hasn't added any links</h3>;
        }

        return links.map((linkEntry, selectedLinkIndex) => {
            return _renderUserLinkItem(linkEntry, selectedLinkIndex);
        });
    };

    const _renderUserPhoto = () => {
        if (!selected || !selected.id) {
            return null;
        }

        return (
            <img src={userPhoto} alt={`${selected.name}`} title={selected.name} onError={imgLoadError} width="100%" />
        );
    };

    const imgLoadError = () => setUserHasPhoto(false);

    const handleTeamView = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (selected.team) {
            dispatch(setTeamsSelected(`${selected.team}`));
            history.push(`/team/${selected.team}`);
        }
    };

    const handleUserPhoto = (isChange?: boolean) => () => {
        // if (isChange) {
        //     console.log('TODO CHECK AFTER USER PHOTO CHANGE');
        // }

        return dispatch(setModalOpen(ModalType.PHOTO_ADD));
    };

    const _renderPhotoOptions = () => {
        if (!isCurrentUser && !isUserAdmin) {
            return null;
        }

        if (userHasPhoto) {
            return (
                <div className="mycrew-user-page__photo-options" onClick={handleUserPhoto()}>
                    <div className="mycrew-user-page__photo-options-label">
                        <div className="mycrew-user-page__photo-options-label--icon">
                            <Edit className="label-icon" />
                        </div>
                    </div>
                    <div className="mycrew-user-page__photo-options--cta">
                        <Edit className="button-icon" />
                        <h4 className="mycrew-user-page__photo-options--title">{Translation.PHOTO_CHANGE}</h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="mycrew-user-page__photo-options" onClick={handleUserPhoto(true)}>
                <div className="mycrew-user-page__photo-options-label">
                    <div className="mycrew-user-page__photo-options-label--icon">
                        <AddCircleOutline className="label-icon" />
                    </div>
                </div>
                <div className="mycrew-user-page__photo-options--cta">
                    <AddCircleOutline className="button-icon" />
                    <h4 className="mycrew-user-page__photo-options--title">{Translation.PHOTO_ADD}</h4>
                </div>
            </div>
        );
    };

    return (
        <div className="mycrew-user-page" ref={refUserPage}>
            <div className="mycrew-user-page__photo">
                {_renderUserPhoto()}
                {_renderPhotoOptions()}
            </div>
            <div className="mycrew-user-page__creds">
                <h1>
                    {Translation.HEY_NOW},<br />
                    {Translation.I_AM} <strong>{userName}</strong>
                    {_renderTeamCreds()}
                </h1>
            </div>
            <div className="mycrew-user-page__external">
                <ul className="mycrew-user-page__external-links">
                    {_renderUserLinkItems()}
                    {_renderUserLinkAddButton()}
                </ul>
            </div>
            <div className={aboutClassName}>
                <h3>{selected.name}</h3>
                {_renderEditorButtons()}
                {_renderCharCounter()}
                <span className={editorClassName}>
                    <Editor
                        editorState={editorText}
                        onChange={(es) => setEditorText(es)}
                        readOnly={!isEditorActive}
                        spellCheck={false}
                        stripPastedStyles={true}
                    />
                </span>
            </div>
        </div>
    );
};

export default UserPage;
