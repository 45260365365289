import { LinkTypeName } from 'constants/CommonConstants';
import { ModalInputTypes } from 'constants/PageConstants';
import { IModalState } from 'interfaces/common';

import {
    MODAL_LOADING_END,
    MODAL_LOADING_START,
    MODAL_SET_CLOSED,
    MODAL_SET_OPEN,
    MODAL_UPDATING_END,
    MODAL_UPDATING_START,
    ModalActionTypes
} from 'state/actions/ModalActionTypes';
import {
    USER_LINK_ADD_FAIL,
    USER_LINK_ADD_LOADING,
    USER_LINK_ADD_SUCCESS,
    USER_LINK_DELETE_FAIL,
    USER_LINK_DELETE_LOADING,
    USER_LINK_DELETE_SUCCESS,
    USER_PHOTO_ADD_FAIL,
    USER_PHOTO_ADD_LOADING,
    USER_PHOTO_ADD_SUCCESS,
    UserActionTypes
} from 'state/actions/UserActionTypes';

const defaultState: IModalState = {
    config: {
        'link-add': {
            type: {
                homepage: {
                    options: [LinkTypeName.BLOG, LinkTypeName.HOMEPAGE, LinkTypeName.PORTFOLIO],
                    inputs: [ModalInputTypes.VALUE]
                },
                repository: {
                    options: [
                        LinkTypeName.REPOSITORY_BITBUCKET,
                        LinkTypeName.REPOSITORY_GITHUB,
                        LinkTypeName.REPOSITORY_GITLAB,
                        LinkTypeName.REPOSITORY_SOURCE_FORGE
                    ],
                    inputs: [ModalInputTypes.VALUE]
                },
                social: {
                    options: [
                        LinkTypeName.SOCIAL_INSTAGRAM,
                        LinkTypeName.SOCIAL_FACEBOOK,
                        LinkTypeName.SOCIAL_LINKEDIN,
                        LinkTypeName.SOCIAL_MEET_UP,
                        LinkTypeName.SOCIAL_REDDIT,
                        LinkTypeName.SOCIAL_TWITTER
                    ],
                    inputs: [ModalInputTypes.VALUE]
                },
                custom: {
                    options: [],
                    inputs: [ModalInputTypes.VALUE, ModalInputTypes.TITLE]
                }
            }
        }
    },
    isOpen: false,
    loading: false,
    modalType: '',
    updating: false
};

const modalReducer = (state: IModalState = defaultState, action: ModalActionTypes | UserActionTypes): IModalState => {
    switch (action.type) {
        case MODAL_LOADING_START:
        case USER_LINK_ADD_LOADING:
        case USER_LINK_DELETE_LOADING:
        case USER_PHOTO_ADD_LOADING:
            return {
                ...state,
                loading: true
            };

        case MODAL_LOADING_END:
        case USER_LINK_ADD_SUCCESS:
        case USER_LINK_ADD_FAIL:
        case USER_LINK_DELETE_SUCCESS:
        case USER_LINK_DELETE_FAIL:
        case USER_PHOTO_ADD_FAIL:
            return {
                ...state,
                loading: false
            };

        case MODAL_UPDATING_START:
            return {
                ...state,
                updating: true
            };

        case MODAL_UPDATING_END:
            return {
                ...state,
                updating: false
            };

        case MODAL_SET_OPEN:
            return {
                ...state,
                isOpen: true,
                ...action.payload
            };

        case MODAL_SET_CLOSED:
            return {
                ...state,
                isOpen: false
            };

        case USER_PHOTO_ADD_SUCCESS:
            return {
                ...state,
                isOpen: false,
                loading: false,
                ...action.payload
            };

        default:
            return state;
    }
};

export default modalReducer;
