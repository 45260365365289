import React from "react";
import {LockOpen} from "@material-ui/icons";
import {Defaults, Translation} from "../../../../constants/CommonConstants";
import {Link} from "@material-ui/core";

export const LogoutButton: React.FC = () => {
  return (
    <form method="POST" action={`${Defaults.URL}/logout`}>
      <Link
        component="button"
        type="submit"
        className="mycrew-user-panel__logout"
      >
        <LockOpen className="mycrew-user-panel__logout-icon"/>
        {Translation.LOG_OUT}
      </Link>
    </form>
  )
}
