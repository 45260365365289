import apiGo from 'api/api';
import API_CONFIG from 'api/apiConfig';

import { ILink, ISSOProfileObject, IUser } from 'interfaces/common';
import { Dispatch } from 'redux';

import {
    USER_FAIL,
    USER_LINK_ADD_FAIL,
    USER_LINK_ADD_LOADING,
    USER_LINK_ADD_SUCCESS,
    USER_LINK_DELETE_FAIL,
    USER_LINK_DELETE_LOADING,
    USER_LINK_DELETE_SUCCESS,
    USER_LINK_INDEX_SUCCESS,
    USER_LOADING,
    USER_PHOTO_ADD_FAIL,
    USER_PHOTO_ADD_LOADING,
    USER_PHOTO_ADD_SUCCESS,
    USER_SELECTED_FAIL,
    USER_SELECTED_LOADING,
    USER_SELECTED_SUCCESS,
    USER_SET_CURRENT,
    USER_SUCCESS,
    UserActionTypes
} from 'state/actions/UserActionTypes';

export const getUsers = () => async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
        type: USER_LOADING
    });

    return apiGo
        .get(API_CONFIG.USER)
        .then((response) => {
            dispatch({
                type: USER_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_FAIL,
                payload: error.message
            });
        });
};

export const setCurrentUser =
    (listOfUsers: Array<IUser>, loginUser: ISSOProfileObject) => async (dispatch: Dispatch<UserActionTypes>) => {
        dispatch({
            type: USER_SET_CURRENT,
            payload: listOfUsers.find((user) => user.email === loginUser.email)
        });
    };

export const getUserSelected = (userId: string) => async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
        type: USER_SELECTED_LOADING
    });

    return apiGo
        .get(`${API_CONFIG.USER}/${userId}`)
        .then((response) => {
            dispatch({
                type: USER_SELECTED_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_SELECTED_FAIL,
                payload: error.message
            });
        });
};

export const patchUserSelected = (userId: string, payload: IUser) => async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
        type: USER_SELECTED_LOADING
    });

    return apiGo
        .patch(`${API_CONFIG.USER}/${userId}`, payload)
        .then((response) => {
            dispatch({
                type: USER_SELECTED_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_SELECTED_FAIL,
                payload: error.message
            });
        });
};

export const addUserLink = (userId: string, payload: ILink) => async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
        type: USER_LINK_ADD_LOADING,
        payload: true
    });

    return apiGo
        .post(`${API_CONFIG.USER}/${userId}/${API_CONFIG.LINK}`, payload)
        .then((response) => {
            dispatch({
                type: USER_LINK_ADD_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_LINK_ADD_FAIL,
                payload: error.message
            });
        });
};

export const setUserSelectedLinkIndex = (selectedLinkIndex: number) => async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
        type: USER_LINK_INDEX_SUCCESS,
        payload: selectedLinkIndex
    });
};

export const removeUserLink = (userId: string, payload: ILink) => async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
        type: USER_LINK_DELETE_LOADING
    });

    return apiGo
        .delete(`${API_CONFIG.USER}/${userId}/${API_CONFIG.LINK}`, { data: payload })
        .then((response) => {
            dispatch({
                type: USER_LINK_DELETE_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_LINK_DELETE_FAIL,
                payload: error.message
            });
        });
};

export const addUserAvatar = (userId: string, payload: any) => async (dispatch: Dispatch<UserActionTypes>) => {
    dispatch({
        type: USER_PHOTO_ADD_LOADING,
        payload: payload
    });

    let postData = new FormData();
    postData.append('file', payload);
    postData.append('name', payload?.name);

    return apiGo
        .post(`${API_CONFIG.USER}/${userId}/${API_CONFIG.AVATAR}`, postData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            dispatch({
                type: USER_PHOTO_ADD_SUCCESS,
                payload: response.data
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_PHOTO_ADD_FAIL,
                payload: error.message
            });
        });
};
