import React from 'react';
import { useDispatch } from 'react-redux';

import { Translation } from 'constants/CommonConstants';

import { setModalClosed } from 'state/actions/ModalActions';

import { Button } from '@material-ui/core';

import 'components/molecules/Overlay/footer/OverlayFooter.scss';

export const OverlayFooter = () => {
    const dispatch = useDispatch();

    return (
        <div className="mycrew-overlay-footer">
            <Button
                className="mycrew-overlay-footer__close"
                variant="contained"
                disableElevation={true}
                onClick={() => dispatch(setModalClosed())}
            >
                {Translation.CLOSE}
            </Button>
        </div>
    );
};

export default OverlayFooter;
