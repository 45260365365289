import React, { FunctionComponent, memo } from 'react';
import { ISVGAttributes } from 'interfaces/svg';

const LogoSymbol: FunctionComponent<ISVGAttributes> = (props) => {
    const { className, iconColor, iconSize } = props;

    return (
        <svg
            className={className}
            height={iconSize ? `${iconSize}px` : '64px'}
            preserveAspectRatio="xMidYMid"
            viewBox="268.31 118.93 73.82 77.76"
            width={iconSize ? `${iconSize}px` : '64px'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={iconColor}
                d="M296.62 195.96c-9.93-2.53-19.21-9.75-23.82-18.53-2.29-4.35-4.29-11.49-4.31-15.39l-.01-1.84 36.49.17 36.48.18-.18 3c-.85 13.57-10.57 26.25-23.87 31.11-5.64 2.06-15.39 2.67-20.78 1.3zm-16.15-43.7a18.25 18.25 0 0 1-11.16-10c-1.5-3.61-1.29-10.02.48-13.66a18.37 18.37 0 0 1 9.89-8.68c3.8-1.3 6.32-1.31 10.51-.06 15.25 4.55 15.5 26.85.37 32-3.16 1.08-7.3 1.24-10.09.4zm8.62-11.77c2.86-2.4 2.9-6.2.1-9-2.45-2.45-5.22-2.55-7.92-.28-2.55 2.15-3.12 4.45-1.78 7.28 1.88 3.94 6.22 4.85 9.6 2zm23.38 8.05-3.63-3.68 4.48-4.48 4.48-4.48-4.48-4.51-4.48-4.52 3.81-3.82 3.82-3.81 4.51 4.48 4.52 4.48 4.49-4.48 4.49-4.5 3.82 3.83 3.82 3.82-4.48 4.52-4.48 4.51 4.48 4.5 4.5 4.48-3.83 3.82-3.82 3.82-4.52-4.49-4.53-4.49-4.3 4.34c-2.36 2.38-4.46 4.33-4.66 4.33-.21 0-2.01-1.65-4-3.67z"
            />
        </svg>
    );
};

export default memo(LogoSymbol);
