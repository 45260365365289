import Loading from 'components/svgs/32/Loading';
import { Translation } from 'constants/CommonConstants';
import { IRootStore } from 'interfaces/common';
import React from 'react';
import { useSelector } from 'react-redux';

import 'components/molecules/Modal/loader/ModalLoader.scss';

export const ModalLoader: React.FunctionComponent = () => {
    const { loading } = useSelector((state: IRootStore) => state.modal);

    if (!loading) {
        return null;
    }

    return (
        <div className="modal-loader">
            <div className="modal-loader__content">
                <Loading className="modal-loader__spinner" />
                <h4 className="modal-loader__title">{Translation.PLEASE_WAIT}</h4>
            </div>
        </div>
    );
};

export default ModalLoader;
