export const TEAM_LOADING = 'TEAM_LOADING';
export const TEAM_SUCCESS = 'TEAM_SUCCESS';
export const TEAM_FAIL = 'TEAM_FAIL';
export const TEAM_SELECTED = 'TEAM_SELECTED';

export interface ITeamLoading {
    type: typeof TEAM_LOADING;
}

export interface ITeamSuccess {
    type: typeof TEAM_SUCCESS;
    payload: Array<string>;
}

export interface ITeamFail {
    type: typeof TEAM_FAIL;
}

export interface ITeamSelected {
    type: typeof TEAM_SELECTED;
    payload: string;
}

export type TeamActionTypes = ITeamLoading | ITeamSuccess | ITeamFail | ITeamSelected;
