import { IUser } from 'interfaces/common';

export const DIRECTORY_LOADING = 'DIRECTORY_LOADING';
export const DIRECTORY_SUCCESS = 'DIRECTORY_SUCCESS';
export const DIRECTORY_FAIL = 'DIRECTORY_FAIL';

export interface IDirectoryLoading {
    type: typeof DIRECTORY_LOADING;
}

export interface IDirectorySuccess {
    type: typeof DIRECTORY_SUCCESS;
    payload: Array<IUser>;
}

export interface IDirectoryFail {
    type: typeof DIRECTORY_FAIL;
}

export type DirectoryActionTypes = IDirectoryLoading | IDirectorySuccess | IDirectoryFail;
