import { IUserState } from 'interfaces/common';
import { LOGIN_EJECT, LoginActionTypes } from 'state/actions/LoginActionsTypes';
import { MODAL_SET_OPEN, ModalActionTypes } from 'state/actions/ModalActionTypes';
import {
    USER_FAIL,
    USER_LINK_ADD_FAIL,
    USER_LINK_ADD_SUCCESS,
    USER_LINK_DELETE_FAIL,
    USER_LINK_DELETE_SUCCESS,
    USER_LINK_INDEX_SUCCESS,
    USER_LOADING,
    USER_PHOTO_ADD_SUCCESS,
    USER_SELECTED_FAIL,
    USER_SELECTED_LOADING,
    USER_SELECTED_SUCCESS,
    USER_SET_CURRENT,
    USER_SUCCESS,
    UserActionTypes
} from 'state/actions/UserActionTypes';

const defaultState: IUserState = {
    currentUser: undefined,
    isUserUpdated: false,
    loading: false,
    selected: {},
    selectedLinkIndex: undefined,
    userErrors: false,
    users: []
};

const userReducer = (
    state: IUserState = defaultState,
    action: UserActionTypes | ModalActionTypes | LoginActionTypes
): IUserState => {
    switch (action.type) {
        case LOGIN_EJECT:
            return defaultState;

        case USER_LOADING:
        case USER_SELECTED_LOADING:
            return {
                ...state,
                loading: true,
                selected: {},
                userErrors: false
            };

        case USER_SET_CURRENT:
            return {
                ...state,
                currentUser: action.payload
            };

        case USER_SUCCESS:
            return {
                ...state,
                isUserUpdated: false,
                loading: false,
                userErrors: false,
                users: action.payload
            };

        case USER_SELECTED_SUCCESS:
            return {
                ...state,
                isUserUpdated: false,
                loading: false,
                selected: action.payload,
                userErrors: false
            };

        case MODAL_SET_OPEN:
            return {
                ...state,
                isUserUpdated: false,
                loading: false,
                userErrors: false
            };

        case USER_LINK_ADD_SUCCESS:
        case USER_LINK_DELETE_SUCCESS:
            return {
                ...state,
                isUserUpdated: true,
                loading: false,
                selected: {
                    ...state.selected,
                    links: action.payload
                },
                userErrors: false
            };

        case USER_PHOTO_ADD_SUCCESS:
            return {
                ...state,
                isUserUpdated: true
            };

        case USER_LINK_INDEX_SUCCESS:
            return {
                ...state,
                selectedLinkIndex: action.payload
            };

        case USER_FAIL:
        case USER_SELECTED_FAIL:
            return {
                ...state,
                loading: false,
                userErrors: true
            };

        case USER_LINK_ADD_FAIL:
        case USER_LINK_DELETE_FAIL:
            return {
                ...state,
                isUserUpdated: true,
                loading: false,
                userErrors: true
            };

        default:
            return state;
    }
};

export default userReducer;
