import React from 'react';

import { IFocusBody } from 'interfaces/common';

import 'components/molecules/Modal/body/ModalBody.scss';

export const ModalBody: React.FunctionComponent<IFocusBody> = ({ content, classSuffix }) => {
    const classBase = 'mycrew-modal__content';
    const classModalBody = `${classBase}${classSuffix ? ` ${classBase}--${classSuffix}` : ''}`;

    return <div className={classModalBody}>{content}</div>;
};

export default ModalBody;
