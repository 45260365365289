import React from 'react';

interface ComponentProps {
    text: string;
    textVars?: [];
}

type Props = ComponentProps;

export const Message: React.FunctionComponent<Props> = ({ text, textVars }) => {
    if (!text) {
        return null;
    }

    // TODO Parse translation variables
    // if (textVars?.length !== 0) {}
    // const textParseVars

    const parseWhiteSpace = (lineOfText: string): string => {
        if (lineOfText.lastIndexOf(' ') === lineOfText.length - 1) {
            return parseWhiteSpace(lineOfText.substr(0, lineOfText.lastIndexOf(' ')));
        }

        return lineOfText;
    };

    // TODO Parse translation line breaks
    const textParseBreaks = () => {
        const parsedText = text.split('n/');
        const parsedLines = parsedText.map((lineOfText) => {
            if (lineOfText.lastIndexOf(' ') === lineOfText.length - 1) {
                return parseWhiteSpace(lineOfText);
            }

            return lineOfText;
        });

        if (parsedLines) {
            return parsedLines.map((textLine: string) => {
                return (
                    <span className="photo-add__drop-text--line" key={textLine}>
                        {textLine}
                    </span>
                );
            });
        }

        return parsedText;
    };

    return <span>{textParseBreaks()}</span>;
};

export default Message;
