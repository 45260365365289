import React from 'react';
import { Translation } from 'constants/CommonConstants';
import { IParsedImage, IPhotoFile } from 'interfaces/common';

export const setCaretAtEnd = (contentEditableElement: any) => {
    var range, selection;

    if (document.createRange) {
        range = document.createRange();
        range.selectNodeContents(contentEditableElement);
        range.collapse(false);
        selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        // @ts-ignore
    } else if (document.selection) {
        // @ts-ignore
        range = document.body.createTextRange();
        range.moveToElementText(contentEditableElement);
        range.collapse(false);
        range.select();
    }
};

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {string} imageType - Image Mime Type
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(
    imageSrc: string,
    imageType: string,
    pixelCrop: any,
    rotation = 0
): Promise<IParsedImage | null> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    if (!ctx) {
        return null;
    }

    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = 'source-over';

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
        canvas.toBlob(
            (file) => {
                const blobFile = {
                    blob: file,
                    preview: URL.createObjectURL(file)
                };

                resolve(blobFile as IParsedImage);
            },
            imageType,
            0.8
        );
    });
}

export const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export const firstToUppercase = (inputWord: string) => inputWord.charAt(0).toUpperCase() + inputWord.slice(1);

export const blobToBase64 = (blob: IPhotoFile) => {
    const reader = new FileReader();

    reader.readAsDataURL(blob);

    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

export const getByteSize = (a: number, b = 2, k = 1024) => {
    let d = Math.floor(Math.log(a) / Math.log(k));
    return 0 === a
        ? '0 Bytes'
        : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
              ' ' +
              ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
};

export const getRandomIndex = (indexGroup: any[]) => {
    if (!indexGroup) {
        return null;
    }

    const numMin = 0;
    const numMax = indexGroup.length;
    const randomIndex = Math.floor(Math.random() * (numMax - numMin)) + numMin;

    return indexGroup[randomIndex];
};

export const getRandomSuccessMessage = () => {
    const successMessages = [
        Translation.AWESOME,
        Translation.EPIC,
        Translation.HELLO_THERE,
        Translation.SUCCESS,
        Translation.THAT_WAS_FUN,
        Translation.WICKED,
        Translation.YOU_ARE_IN
    ];

    const date = new Date();

    if (!successMessages.length || date.getHours() < 16) {
        return Translation.SUCCESS;
    }

    if (date.getHours() === 10 && date.getMinutes() === 4) {
        return Translation.GIGAWATT;
    }

    const numMin = 0;
    const numMax = successMessages.length;
    const messageIndex = Math.floor(Math.random() * (numMax - numMin)) + numMin;

    return `${successMessages[messageIndex]} ${messageIndex}`;
};

export const scrollTop = (isAnimated?: boolean, isUserPage= false) => {
    const appRef = document.querySelector(".mycrew");

    if (!appRef || appRef.scrollTop === 0) {
        return null;
    }

    setTimeout(() => {
        return appRef.scrollTo({ top: isUserPage ? 190 : 0, behavior: isAnimated ? 'smooth' : undefined });
    }, 200);
};

export function focusAndOpenKeyboard(forwardElement: HTMLInputElement, timeout: number = 120) {
    if (forwardElement) {
        let _hiddenElement = document.createElement('input');
        _hiddenElement.style.position = 'absolute';
        _hiddenElement.style.top = (forwardElement.offsetTop + 7) + 'px';
        _hiddenElement.style.left = forwardElement.offsetLeft + 'px';
        _hiddenElement.style.height = '0';
        _hiddenElement.style.opacity = '0';
        document.body.appendChild(_hiddenElement);
        _hiddenElement.focus();

        setTimeout(function() {
            forwardElement.focus();
            forwardElement.click();
            document.body.removeChild(_hiddenElement);
        }, timeout);
    }
}
