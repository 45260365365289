import { Dispatch } from 'redux';

import { SEARCH_FOCUS, SearchActionTypes } from 'state/actions/SearchActionTypes';

export const setSearchFocus =
    (isFocus: boolean = true) =>
    async (dispatch: Dispatch<SearchActionTypes>) => {
        dispatch({
            type: SEARCH_FOCUS,
            payload: isFocus
        });
    };
