import React from 'react';
import { useDispatch } from 'react-redux';

import { setModalClosed } from 'state/actions/ModalActions';

import { Translation } from 'constants/CommonConstants';

import { Close } from '@material-ui/icons';
import { Link } from '@material-ui/core';

import 'components/molecules/Modal/header/ModalHeader.scss';

interface IProps {
    title: string;
    onClose?(): void;
}

export const ModalHeader: React.FunctionComponent<IProps> = ({ title, onClose }) => {
    const dispatch = useDispatch();

    const closeModal = (event: React.SyntheticEvent) => {
        event.preventDefault();
        return dispatch(setModalClosed());
    };

    return (
        <div className="mycrew-modal__header">
            <span className="mycrew-modal__title">{title}</span>
            <Link
                className="mycrew-modal__close"
                href={`#${Translation.CLEAR}`}
                onClick={(event: React.SyntheticEvent) => closeModal(event)}
                title={Translation.CLEAR}
                underline="none"
            >
                <Close className="mycrew-modal__close--icon" />
            </Link>
        </div>
    );
};

export default ModalHeader;
