import React from 'react';
import { ContactSupport, DoneAll } from '@material-ui/icons';
import ModalContext from 'components/molecules/Modal/context/ModalContext';
import { useDispatch, useSelector } from 'react-redux';
import { removeUserLink } from 'state/actions/UserActions';

import { IRootStore } from 'interfaces/common';
import { Translation } from 'constants/CommonConstants';

import 'components/pages/UserPage/links/delete/LinkDelete.scss';

export const LinkDelete = () => {
    const { selectedLinkIndex, selected, isUserUpdated, userErrors } = useSelector((state: IRootStore) => state.user);

    const dispatch = useDispatch();

    if (!selected || typeof selectedLinkIndex !== 'number') {
        return null;
    }

    const handleLinkDelete = () => {
        const { id, links } = selected;

        if (!id || !links) {
            return null;
        }

        dispatch(removeUserLink(id, links[selectedLinkIndex]));

        return null;
    };

    const renderContent = () => (
        <div className="mycrew-modal__content-block">
            <p className="mycrew-modal__paragraph">{Translation.LINK_DELETE_CONFIRM}</p>
        </div>
    );

    const renderSuccess = () => (
        <>
            <DoneAll />
            <p>{Translation.LINK_DELETE_SUCCESS}</p>
        </>
    );

    const renderError = () => (
        <>
            <ContactSupport />
            <p>{Translation.PROBLEM}</p>
        </>
    );

    if (isUserUpdated && userErrors) {
        return (
            <ModalContext
                classSuffix="error"
                content={renderError()}
                isErroneous={true}
                isSoloFooterOption={true}
                theme="primary"
                title={Translation.ERROR}
            />
        );
    }

    if (isUserUpdated) {
        return (
            <ModalContext
                content={renderSuccess()}
                classSuffix="success"
                isSoloFooterOption={true}
                theme="primary"
                title={Translation.SUCCESS}
            />
        );
    }

    return (
        <ModalContext
            content={renderContent()}
            onConfirm={handleLinkDelete}
            textConfirm={Translation.DELETE}
            theme="secondary"
            title={Translation.DELETE}
        />
    );
};

export default LinkDelete;
