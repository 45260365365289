import React from 'react';

import { IModalOverlayProps } from 'interfaces/common';

import ModalLoader from 'components/molecules/Modal/loader/ModalLoader';
import OverlayBody from 'components/molecules/Overlay/body/OverlayBody';
import OverlayFooter from 'components/molecules/Overlay/footer/OverlayFooter';
import OverlayHeader from 'components/molecules/Overlay/header/OverlayHeader';

import 'components/molecules/Overlay/context/OverlayContext.scss';

export const OverlayContext: React.FunctionComponent<IModalOverlayProps> = ({ title, content }) => {
    return (
        <div className="mycrew-overlay">
            <div className="mycrew-overlay__wrapper">
                <OverlayHeader title={title} />
                <OverlayBody content={content} />
                <OverlayFooter />
                <ModalLoader />
            </div>
        </div>
    );
};

export default OverlayContext;
