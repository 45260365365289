import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import API_CONFIG from 'api/apiConfig';

import { Translation } from 'constants/CommonConstants';

import { IRootStore } from 'interfaces/common';

import { setModalClosed } from 'state/actions/ModalActions';
import { setTeamsSelected } from 'state/actions/TeamActions';

import Link from '@material-ui/core/Link';
import Scrollbars from 'react-custom-scrollbars';
import { ChevronRight } from '@material-ui/icons';

import OverlayContext from 'components/molecules/Overlay/context/OverlayContext';

import 'components/pages/TeamPage/overview/TeamOverview.scss';

export const TeamOverview = () => {
    const { teams, selected } = useSelector((state: IRootStore) => state.team);
    const dispatch = useDispatch();
    const classBase = 'mycrew-team-overview';

    const handleClick = (teamName: string) => {
        dispatch(setModalClosed());
        dispatch(setTeamsSelected(teamName));
    };

    const renderTeamOverviewContent = () => {
        if (!teams?.length) {
            return <li key={Translation.NO_RESULTS}>{Translation.NO_RESULTS}</li>;
        }

        const sortedTeams = [...teams]?.sort((a, b) => a.localeCompare(b));

        const listOfTeams = () => {
            return sortedTeams?.map((teamName) => {
                return (
                    <li key={teamName} className={`${classBase}__list-item`}>
                        <Link
                            className={`${classBase}__list-link${
                                selected === teamName ? ` ${classBase}__list-link--selected` : ''
                            }`}
                            color={'inherit'}
                            component={RouterLink}
                            onClick={() => handleClick(teamName)}
                            title={teamName}
                            to={`/${API_CONFIG.TEAM}/${teamName}`}
                            underline="none"
                        >
                            {teamName}
                            <span className={`${classBase}__list-arrow`}>
                                <ChevronRight />
                            </span>
                        </Link>
                    </li>
                );
            });
        };

        return <ul className={`${classBase}__list`}>{listOfTeams()}</ul>;
    };

    const renderTeamOverview = () => {
        return (
            <div className={classBase}>
                <Scrollbars style={{ height: '100%' }}>{renderTeamOverviewContent()}</Scrollbars>
            </div>
        );
    };

    return <OverlayContext content={renderTeamOverview()} title={Translation.OVERLAY_ALL_TEAMS_TITLE} />;
};

export default TeamOverview;
